import React, { Component, Fragment } from 'react';
import ClientSignatoryItem from './client_signatory_item';
import InputSection from '../layouts/input_section_header';

export default class ClientSignatoriesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientSignatories: this.props.clientSignatories || [],
    };
  }

  render() {
    return (
      <Fragment>
        <InputSection title="Client Signatories">
          <p className="help-block">{I18n.t('properties.form.client_signatory_help')}</p>
          <div className="row">
            <div className="client-signatories__section">
              {this.state.clientSignatories.map((clientSignatory, index) => {
                return (
                  <ClientSignatoryItem
                    id={clientSignatory.id}
                    leaseId={this.props.leaseId}
                    key={clientSignatory.id || Math.random()}
                    index={index}
                    onSignatoryAdded={this.addSavedSignatory}
                    onSignatoryDeleted={this.removeSignatory}
                    removeUnsavedSignatory={this.removeUnsavedSignatory}
                    numberOfSignatories={this.savedSignatories.length}
                    clientSignatory={clientSignatory}
                  />
                );
              })}
              {this.showAddSignatoryButton && (
                <div className="col-sm-6">
                  <button onClick={this.addAdditionalSignatory} className="btn btn-info">
                    Add additional signatory
                  </button>
                </div>
              )}
            </div>
          </div>
        </InputSection>
      </Fragment>
    );
  }

  addAdditionalSignatory = (e) => {
    e.preventDefault();

    let signatories = this.state.clientSignatories;
    signatories.push({});
    this.setState({
      clientSignatories: signatories,
    });
  };

  addSavedSignatory = (newSignatory) => {
    const clientSignatories = this.state.clientSignatories.map((signatory) => {
      if (!signatory.id) {
        return { id: newSignatory.id, ...newSignatory };
      }
      return signatory;
    });
    this.setState({ clientSignatories });
  };

  removeSignatory = (removed) => {
    const clientSignatories = this.state.clientSignatories.filter((signatory) => signatory.id !== removed.id);
    this.setState({ clientSignatories });
  };

  removeUnsavedSignatory = () => {
    const clientSignatories = this.savedSignatories;
    this.setState({ clientSignatories });
  };

  get savedSignatories() {
    return this.state.clientSignatories.filter((signatory) => signatory.id);
  }

  get showAddSignatoryButton() {
    return this.state.clientSignatories.length < 2;
  }
}
