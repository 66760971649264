import React from 'react';
import SettingsIcon from 'images/settings.svg';

export default function InternalOnly({ children }) {
  return (
    <div className="well property-form-group property-form-group--internal-only">
      <div className="property-form-group__inner">
        <div className="property-form-group__header">
          <img src={SettingsIcon} className="property-form-group__icon" />
          <h3 className="property-form-group__title">Internal Only</h3>
        </div>
        {children}
      </div>
    </div>
  );
}
