import React from 'react';
import { copyEventTargetText, queryParameter, baseURL } from './utils';
import SearchParameters from './search_parameters';
import PropertyInputs from './property_inputs';
import Comparables from './comparables';
import Error from './error';
import cuid from 'cuid';

export default class PriceCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign(
      {
        address: null,
        postcode: null,
        lat: null,
        lon: null,
        radius: 0.25,
        max_age: 180,
        area: 0,
        area_range: 100,
        floor_number: '',
        furnish_type: '',
        property_type: '',
        bedrooms: '',
        comparables: [],
        searching: false,
        saving: false,
        loading: false,
        error: null,
        valuation_id: null,
        session_id: cuid(),
      },
      this.props.initialState
    );
  }

  componentDidMount() {
    // Added all this grimness because PCA updates the DOM elements
    // but React isn't notified.
    if (typeof pca == 'object') {
      pca.on('load', (type, id, control) => {
        control.listen('populate', (payload) => this.onPCAPopulated(payload));
      });
      if (typeof pca.load == 'function') {
        pca.load();
      }
    }

    this.loadState();
    window.addEventListener('popstate', this.loadState);
  }

  setState(updates = {}, cb = null) {
    // Clear valuation_id if any search-defining state changes.
    if (
      updates['valuation_id'] === undefined &&
      (updates['address'] !== undefined ||
        updates['postcode'] !== undefined ||
        updates['lat'] !== undefined ||
        updates['lon'] !== undefined ||
        updates['radius'] !== undefined ||
        updates['max_age'] !== undefined ||
        updates['area'] !== undefined ||
        updates['area_range'] !== undefined ||
        updates['floor_number'] !== undefined ||
        updates['furnish_type'] !== undefined ||
        updates['comparables'] !== undefined ||
        updates['searching'] !== undefined ||
        updates['bedrooms'] !== undefined ||
        updates['property_type'] !== undefined)
    ) {
      updates['valuation_id'] = null;
    }
    super.setState(updates, cb);
  }

  render() {
    return (
      <div className="price-calculator container">
        <div className="row">
          <div className="col-sm-3">
            <div className="price-calculator__inputs affix">
              <SearchParameters {...this.state} onChange={(s) => this.setState(s)} />
              <PropertyInputs {...this.state} onChange={(s) => this.setState(s)} />
              <button
                type="button"
                className="btn btn-primary price-calculator__search"
                disabled={this.state.searching}
                onClick={() => this.fetchComparables()}
              >
                Search
              </button>
            </div>
          </div>

          <div className="col-sm-9">
            {this.state.error && <Error error={this.state.error} onClose={() => this.setState({ error: null })} />}

            {(this.state.loading || this.state.searching) && !this.state.error && (
              <div className="price-calculator__loading">
                <p className="fa-2x">
                  <i className="fa fa-spinner fa-spin"></i> Loading...
                </p>
              </div>
            )}

            {!this.state.loading && !this.state.searching && (
              <Comparables {...this.state} comparableChangeHandler={this.onComparableChangeHandler} />
            )}
          </div>
        </div>
      </div>
    );
  }

  onComparableChangeHandler = (rightmove_identifier) => {
    return (selected) => {
      const comparables = this.state.comparables;
      const comparableIndex = comparables.findIndex((c) => c.rightmove_identifier === rightmove_identifier);
      comparables[comparableIndex].selected = selected;
      this.setState({ comparables }, this.saveState);
    };
  };

  onPCAPopulated(payload) {
    this.setState(
      {
        address: payload.Label.replace(/\n/g, ', '),
        postcode: payload.PostalCode,
      },
      () => {
        this.fetchLatLong();
      }
    );
  }

  fetchLatLong() {
    fetch(`${this.props.priceCalculatorHost}/price_calculator/geocode/${this.state.postcode}`, {
      headers: new Headers({
        Authorization: `Bearer ${this.props.jwt}`,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          res.text().then((text) => this.handleError(text));
          return null;
        }
        return res;
      })
      .then((res) => (!res ? null : res.json()))
      .then((json) =>
        !json
          ? null
          : this.setState({
              lat: json[0].Latitude,
              lon: json[0].Longitude,
            })
      )
      .catch((err) => this.handleError(err));
  }

  fetchComparables() {
    this.setState({ searching: true });
    fetch(`${this.props.priceCalculatorHost}/price_calculator/comparables`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${this.props.jwt}`,
      }),
      body: JSON.stringify({
        lat: this.state.lat,
        lon: this.state.lon,
        radius: this.state.radius,
        max_age: this.state.max_age,
        area: this.state.area,
        area_range: this.state.area_range,
        bedrooms: this.state.bedrooms,
        floor_number: this.state.floor_number,
        furnish_type: this.state.furnish_type,
        property_type: this.state.property_type,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          res.text().then((text) => this.handleError(text));
          return null;
        }
        return res;
      })
      .then((res) => (!res ? null : res.json()))
      .then((comparables) => {
        if (!comparables) {
          return null;
        }
        this.setState(
          {
            comparables,
            searching: false,
            session_id: cuid(),
          },
          this.saveState
        );
      })
      .catch((err) => this.handleError(err));
  }

  saveState = () => {
    this.setState({ saving: true });
    fetch(`${this.props.priceCalculatorHost}/price_calculator/valuation`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${this.props.jwt}`,
      }),
      body: JSON.stringify({
        address: this.state.address,
        postcode: this.state.postcode,
        lat: this.state.lat,
        lon: this.state.lon,
        radius: this.state.radius,
        max_age: this.state.max_age,
        area: this.state.area,
        area_range: this.state.area_range,
        floor_number: this.state.floor_number,
        furnish_type: this.state.furnish_type,
        property_type: this.state.property_type,
        bedrooms: this.state.bedrooms,
        comparables: this.state.comparables.map((c) => ({
          rightmove_identifier: c.rightmove_identifier,
          selected: c.selected,
        })),
        session_id: this.state.session_id,
        estimates: {
          mean: this.comparablesMean,
          median: this.comparablesMedian,
          std: this.comparablesStandardDeviation,
        },
      }),
    })
      .then((res) => {
        if (!res.ok) {
          res.text().then((text) => this.handleError(text));
          return null;
        }
        return res;
      })
      .then((res) => (!res ? null : res.json()))
      .then((json) =>
        !json
          ? null
          : this.setState(
              {
                valuation_id: json.id,
                saving: false,
              },
              () => {
                history.pushState(null, null, this.valuationURL());
              }
            )
      )
      .catch((err) => this.handleError(err));
  };

  loadState = () => {
    const requestedID = queryParameter('valuation');

    if (requestedID == null || requestedID == this.state.valuation_id) {
      return;
    }

    this.setState({ loading: true });

    fetch(`${this.props.priceCalculatorHost}/price_calculator/valuation/${requestedID}`, {
      headers: new Headers({
        Authorization: `Bearer ${this.props.jwt}`,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          res.text().then((text) => this.handleError(text));
          return null;
        }
        return res;
      })
      .then((res) => (!res ? null : res.json()))
      .then((json) =>
        !json
          ? null
          : this.setState({
              address: json.address,
              postcode: json.postcode,
              lat: json.lat,
              lon: json.lon,
              radius: json.radius,
              max_age: json.max_age,
              area: json.area,
              area_range: json.area_range,
              floor_number: json.floor_number,
              furnish_type: json.furnish_type,
              property_type: json.property_type,
              bedrooms: json.bedrooms,
              comparables: json.comparables,
              valuation_id: requestedID,
              session_id: json.session_id,
              loading: false,
            })
      )
      .catch((err) => this.handleError(err));
  };

  valuationURL(id) {
    if (id == null) {
      id = this.state.valuation_id;
    }
    if (id == null) {
      return null;
    }
    return `${baseURL()}?valuation=${this.state.valuation_id}`;
  }

  handleError(error) {
    console.error(error);
    this.setState({ error });
  }
}
