import React from 'react';

export default class Delete extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let id = !this.props.id ? this.props.name : this.props.id;

    return (
      <div className="remove-item">
        <input id={id} type="checkbox" name={this.props.name} defaultValue="true" onChange={this.props.onChange} />
        <label htmlFor={id}></label>
      </div>
    );
  }
}
