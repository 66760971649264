module.exports = [
  {
    label: 'Access Code 1 - Code',
    key: 'access_code_1_code',
  },
  {
    label: 'Access Code 1 - Description',
    key: 'access_code_1_description',
  },
  {
    label: 'Access Code 1 - Title',
    key: 'access_code_1_title',
  },
  {
    label: 'Access Code 2 - Code',
    key: 'access_code_2_code',
  },
  {
    label: 'Access Code 2 - Description',
    key: 'access_code_2_description',
  },
  {
    label: 'Access Code 2 - Title',
    key: 'access_code_2_title',
  },
  {
    label: 'Access Instructions',
    key: 'access_instructions',
  },
  {
    label: 'Access Method',
    key: 'access_method',
    type: 'select',
    options: [
      { value: 'key', label: 'Key' },
      { value: 'smart_lock', label: 'Smartlock' },
      { value: 'klevio', label: 'Klevio' },
      { value: 'other', label: 'Other' },
    ],
  },
  {
    label: 'Address Headline',
    key: 'address_headline',
  },
  {
    label: 'Air Conditioning',
    key: 'air_conditioning',
    type: 'checkbox',
  },
  {
    label: 'Appliances',
    key: 'appliances',
  },
  {
    label: 'Area Description',
    key: 'area_description',
  },
  {
    label: 'Available To Move In From',
    key: 'available_from',
  },
  {
    label: 'Bathroom Count',
    key: 'bathrooms_count',
  },
  {
    label: 'Bedroom Count',
    key: 'bedrooms_count',
  },
  {
    label: 'Bike Storage',
    key: 'bike_storage',
    type: 'select',
    options: [
      { value: 'none', label: 'None' },
      { value: 'one_bike', label: 'One Bike' },
      { value: 'two_bikes', label: 'Two Bikes' },
      { value: 'three_bikes', label: 'Three Bikes' },
      { value: 'four_bikes', label: 'Four Bikes' },
      { value: 'unknown', label: 'Number Unknown' },
    ],
  },
  {
    label: 'Boiler location',
    key: 'boiler_location',
  },
  {
    label: 'Build Year',
    key: 'build_year',
  },
  {
    label: 'Central Heating',
    key: 'central_heating',
    type: 'checkbox',
  },
  {
    label: 'Coaxial Outlet',
    key: 'coaxial_outlet',
    type: 'checkbox',
  },
  {
    label: 'Common Name',
    key: 'common_name',
  },
  {
    label: 'Concierge Availability',
    key: 'concierge_availability',
    type: 'select',
    options: [
      { value: 'none', label: 'None' },
      { value: 'concierge', label: 'Concierge' },
      { value: 'porter', label: 'Porter' },
    ],
  },
  {
    label: 'Council',
    key: 'council',
  },
  {
    label: 'County',
    key: 'region',
    alternates: ['County'],
  },
  {
    label: 'Current Internet Provider',
    key: 'current_internet_provider',
  },
  {
    label: 'Electric Meter Location',
    key: 'electric_meter_location',
  },
  {
    label: 'Electric Meter Serial',
    key: 'electric_meter_serial',
  },
  {
    label: 'Floor Number',
    key: 'floor_number',
  },
  {
    label: 'Furnished or Unfurnished',
    key: 'furnished',
  },
  {
    label: 'Fuse Box Location',
    key: 'fuse_box_location',
  },
  {
    label: 'Garbage access',
    key: 'garbage_access',
  },
  {
    label: 'Gas Meter Location',
    key: 'gas_meter_location',
  },
  {
    label: 'Gas Meter Serial',
    key: 'gas_meter_serial',
  },
  {
    label: 'Gas Supply',
    key: 'gas_supply',
    type: 'checkbox',
  },
  {
    label: 'High Ceilings',
    key: 'high_ceilings',
    type: 'checkbox',
  },
  {
    label: 'House Name',
    key: 'house_name',
  },
  {
    label: 'House Number',
    key: 'house_number',
  },
  {
    label: 'In-person viewings possible',
    key: 'viewings_possible',
    type: 'checkbox',
  },
  {
    label: 'Landline Outlet',
    key: 'landline_outlet',
    type: 'checkbox',
  },
  {
    label: 'Lift',
    key: 'lift',
    type: 'checkbox',
  },
  {
    label: 'Meeting Instructions',
    key: 'meeting_instructions',
  },
  {
    label: 'Mngmt Org Property ID',
    key: 'management_organisation_property_identifier',
    alternates: ['Reference'],
  },
  {
    label: 'Neighbourhood',
    key: 'neighbourhood',
  },
  {
    label: 'On site Concierge Access',
    key: 'concierge_notes',
  },
  {
    label: 'Openreach',
    key: 'openreach',
    type: 'select',
    options: [
      { value: 'fibre', label: 'Fibre' },
      { value: 'adsl', label: 'ADSL' },
      { value: 'no_fibre', label: 'No' },
    ],
  },
  {
    label: 'Our Property ID',
    key: 'id',
    alternates: ['ID', 'Residently ID'],
  },
  {
    label: 'Parking Type',
    key: 'parking_type',
    type: 'select',
    options: [
      { value: 'none', label: 'None' },
      { value: 'allocated_included', label: 'Allocated Included' },
      { value: 'allocated_seperate', label: 'Allocated Separate' },
      { value: 'on_street_permit', label: 'On Street Permit' },
      { value: 'first_come_first_served', label: 'First Come First Server' },
    ],
  },
  {
    label: 'Pets Allowed (Y/N)',
    key: 'pets_allowed',
  },
  {
    label: 'Postbox access',
    key: 'postbox_access',
  },
  {
    label: 'Postcode',
    key: 'postcode',
  },
  {
    label: 'Property Size (sqft)',
    key: 'square_feet',
  },
  {
    label: 'Property service type',
    key: 'property_service_type',
  },
  {
    label: 'Property Type',
    key: 'property_type',
    type: 'select',
    options: [
      { value: 'flat', label: 'Flat' },
      { value: 'terrace', label: 'Terrace' },
      { value: 'endterrace', label: 'End Terrace' },
      { value: 'semidetached', label: 'Semi Detached' },
      { value: 'detached', label: 'Detached' },
    ],
  },
  {
    label: 'Reservation possible',
    key: 'reservation_possible',
    type: 'checkbox',
  },
  {
    label: 'Rubbish Collection',
    key: 'rubbish_collection',
  },
  {
    label: 'Satellite Dish',
    key: 'satellite_dish',
    type: 'checkbox',
  },
  {
    label: 'Satellite Outlet',
    key: 'satellite_outlet',
    type: 'checkbox',
  },
  {
    label: 'Stairs To Flat',
    key: 'stairs_to_flat',
  },
  {
    label: 'Street Line 1',
    key: 'street_line_1',
  },
  {
    label: 'Street Line 2',
    key: 'street_line_2',
  },
  {
    label: 'Subpremise',
    key: 'subpremise',
  },
  {
    label: 'Test',
    key: 'test',
    type: 'checkbox',
  },
  {
    label: 'Thermostat Locations',
    key: 'thermostat_locations',
  },
  {
    label: 'Town or City',
    key: 'town_or_city',
    alternates: ['Town', 'City'],
  },
  {
    label: 'TV Licence Number',
    key: 'tv_licence_number',
  },
  {
    label: 'Underfloor Heating',
    key: 'underfloor_heating',
    type: 'checkbox',
  },
  {
    label: 'Unfurnished Price (per month)',
    key: 'price',
  },
  {
    label: 'Viewing Event Meeting Instructions',
    key: 'viewing_event_meeting_instructions',
  },
  {
    label: 'Viewing Notice (in hours)',
    key: 'notice_period',
  },
  {
    label: 'Viewings not possible reason',
    key: 'viewings_not_possible_reason',
    type: 'select',
    options: [
      {
        value: 'acquired_with_residents_in_situ',
        label: 'Acquired with residents in-situ',
      },
      {
        value: 'application_submitted_or_approved',
        label: 'Application submitted or approved',
      },
      {
        value: 'let_by_another_agent',
        label: 'Let by another agent',
      },
      {
        value: 'previous_tenants_or_owner_in_situ',
        label: 'Previous tenants or owner in situ',
      },
      {
        value: 'no_longer_marketed_by_us',
        label: 'No longer marketed by us',
      },
      {
        value: 'under_maintenance',
        label: 'Property under maintenance',
      },
    ],
  },
  {
    label: 'Virtual viewings possible',
    key: 'online_viewings_possible',
    type: 'checkbox',
  },
  {
    label: 'Water Meter Location',
    key: 'water_meter_location',
  },
  {
    label: 'Water Meter Serial',
    key: 'water_meter_serial',
  },
  {
    label: 'Wheelchair Access',
    key: 'wheelchair_access',
    type: 'checkbox',
  },
  {
    label: 'Wifi Description',
    key: 'wifi_description',
  },
  {
    label: 'Wifi Name',
    key: 'wifi_name',
  },
  {
    label: 'Wifi Password',
    key: 'wifi_password',
  },
  {
    label: 'Zone',
    key: 'zone',
  },
];
