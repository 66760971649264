import React from 'react';

export default class PropertySearchItem extends React.Component {
  componentDidUpdate() {
    if (this.props.active == 'active') {
      this.props.scrollIntoView(this.searchResult);
    }
  }

  render() {
    const { item, active } = this.props;
    const tidyNeighbourhoodPostcode = [item.neighbourhood, item.postcode].filter((x) => !!x).join(', ');

    return (
      <div ref={(result) => (this.searchResult = result)} className="search-bar__result">
        <a href={item.url} className={`list-group-item ${active}`}>
          <p className="search-bar__result-id">#{item.id}</p>
          <h4 className="search-bar__result-heading list-group-item-heading">
            <span className="search-bar__result-title">{item.title}</span>
            <img className="search-bar__result-image" src={item.image_url} />
          </h4>
          <p>{tidyNeighbourhoodPostcode}</p>
          <div className="search-bar__result-body">
            <div className="search-bar__result-body-left">
              <div dangerouslySetInnerHTML={{ __html: item.availability }}></div>
              <div dangerouslySetInnerHTML={{ __html: item.viewable }}></div>
              <div dangerouslySetInnerHTML={{ __html: item.viewable_online }}></div>
              <div dangerouslySetInnerHTML={{ __html: item.reservable }}></div>
            </div>
            <div className="search-bar__result-body-right">
              <div className="search-bar__property-result-pricing">
                <p className="search-bar__property-result-pricing-unfurnished">{item.unfurnished}</p>
                <p className="search-bar__property-result-pricing-furnished">{item.furnished}</p>
              </div>
              <p>
                {item.beds} bed / {item.baths} bath
              </p>
            </div>
          </div>

          <p className="search-bar__result-description list-group-item-text">{item.description}</p>
        </a>
      </div>
    );
  }
}
