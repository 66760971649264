import React from 'react';

export default class Comparable extends React.Component {
  get distanceToStation() {
    if (!this.props.distance_to_nearest_station) {
      return;
    }

    return this.props.distance_to_nearest_station + ' miles to the station';
  }

  get floorDescription() {
    if (this.props.floor_number == null) {
      return;
    }

    if (this.props.floor_number == '0') {
      return 'Ground Floor';
    } else if (this.props.floor_number < 0) {
      return 'Basement';
    } else {
      return 'Floor ' + this.props.floor_number;
    }
  }

  get rightmoveURL() {
    return `http://www.rightmove.co.uk/property-to-rent/property-${this.props.rightmove_identifier}.html`;
  }

  render() {
    return (
      <div className="comparable row">
        <div className="col-sm-3">
          <img className="comparable__image" src={this.props.first_photo} />
        </div>
        <div className="col-sm-5">
          <p className="comparable__address">
            <a href={this.rightmoveURL} target="_blank">
              {this.props.address}
            </a>
          </p>
          <p className="comparable_details">
            {this.props.bedrooms} beds, {this.props.furnish_type}
            <br />
            {this.distanceToStation}
            <br />
            {this.floorDescription}
          </p>
        </div>
        <div className="col-sm-2">
          <p className="comparable__area_sqft">{this.props.area_sqft} sqft</p>
        </div>
        <div className="comparable__final_price col-sm-2 text-right">
          <p>
            £{this.props.final_price.toLocaleString()}
            <span className="comparable__days_on_market">{this.props.days_on_market} days on market</span>
          </p>

          <label>
            Compare?
            <input type="checkbox" checked={this.props.selected} onChange={(e) => this.onSelectedChangeHandler(e)} />
          </label>
        </div>
      </div>
    );
  }

  onSelectedChangeHandler = (e) => {
    this.props.onChange(e.target.checked);
  };
}
