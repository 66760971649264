import React from 'react';
import useDataImportWatcher from './use_data_import_watcher';
import format from 'date-fns/format';

const ImportManager = ({ dataImports, isDataImportBreakdownEnabled }) => {
  const renderRows = (dataImportsToRender) =>
    dataImportsToRender.map((dataImport) => (
      <ImportManagerRow
        key={dataImport.id}
        {...dataImport}
        isDataImportBreakdownEnabled={isDataImportBreakdownEnabled}
      />
    ));

  return (
    <table className="data-import table table-striped">
      <thead>
        <tr>
          <th>{I18n.t('data_import.labels.id')}</th>
          <th>{I18n.t('data_import.labels.resource')}</th>
          <th>{I18n.t('data_import.labels.import_type')}</th>
          <th>{I18n.t('data_import.labels.status')}</th>
          <th>{I18n.t('data_import.labels.count')}</th>
          <th>{I18n.t('data_import.labels.created_at')}</th>
          <th>{I18n.t('data_import.labels.imported_by')}</th>
          <th>{I18n.t('data_import.labels.management_org')}</th>
        </tr>
      </thead>
      <tbody>{renderRows(dataImports)}</tbody>
    </table>
  );
};

const ImportManagerRow = ({
  id,
  resource,
  status,
  data_count,
  review_count,
  created_at,
  additional_params,
  isDataImportBreakdownEnabled,
  results_count,
}) => {
  const liveValues = useDataImportWatcher({ id, status, review_count, results_count });
  const importedAt = format(new Date(created_at), 'dd/MM/yyyy HH:mm');

  const countText = [data_count, liveValues.reviewCount > 0 && `(${liveValues.reviewCount} pending review)`]
    .filter(Boolean)
    .join(' ');

  const onClick = () => {
    isDataImportBreakdownEnabled && (results_count || liveValues.resultsCount)
      ? (window.location.href = `/data_imports/${id}`)
      : null;
  };

  return (
    <tr onClick={onClick} className="data_import-row">
      <td>{id}</td>
      <td>{I18n.t(`data_import.resources.${resource}`)}</td>
      <td>{I18n.t(`data_import.type.${additional_params.import_type}`)}</td>
      <td>
        <span className={`data_import__status--${liveValues.status}`}>
          {I18n.t(`data_import.statuses.${liveValues.status}`)}
        </span>
      </td>
      <td>{countText}</td>
      <td>{importedAt}</td>
      <td>{additional_params.imported_by.name}</td>
      <td>{additional_params.managementId}</td>
    </tr>
  );
};

export default ImportManager;
