import React, { Component } from 'react';

import DeleteIcon from '~/components/forms/delete_icon';

import HttpRequestor from '~/utils/http_requestor';

export default class DeleteOrganisationPortfolio extends Component {
  constructor(props) {
    super(props);

    console.log(props);

    this.http = new HttpRequestor();
  }

  onDeleteClick = (e) => {
    e.preventDefault();

    const confirmed = confirm('Do you want to remove access to this portfolio?');

    if (confirmed) {
      this.deleteOrganisationPortfolio();
    }
  };

  deleteOrganisationPortfolio = () => {
    this.http.call(this.organisationPortfolioUrl, 'DELETE').then(() => window.location.reload());
  };

  get organisationPortfolioUrl() {
    return `/organisations/${this.props.organisationId}/portfolios/${this.props.id}`;
  }

  render() {
    return (
      <div className="pull-right">
        <DeleteIcon name={`organisation_portfolio_${this.props.id}`} onChange={this.onDeleteClick} />
      </div>
    );
  }
}
