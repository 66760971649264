import React, { useEffect, useState } from 'react';

const CsvParserFieldMatcher = ({ fields, initialMatches, onChange }) => {
  const [matches, setMatches] = useState(initialMatches);

  useEffect(() => {
    onChange(matches);
  }, [matches]);

  const onFieldChange = (match, index, key) => {
    match.field = key && fields.find((field) => field.key === key);
    match.excluded = false;
    match.confirmed = false;
    matches.splice(index, 1, match);
    setMatches([...matches]);
  };

  const onIgnoreChange = (match, index) => {
    match.excluded = !match.excluded;
    match.confirmed = false;
    matches.splice(index, 1, match);
    setMatches([...matches]);
  };

  const onConfirmChange = (match, index) => {
    match.confirmed = !match.confirmed;
    match.excluded = false;
    matches.splice(index, 1, match);
    setMatches([...matches]);
  };

  return (
    <div>
      <h2>Map Fields</h2>
      <div className="alert alert-warning">Note: Every field must be Excluded or Confirmed.</div>

      {matches.map((match, index) => (
        <div
          className={'panel ' + `panel-${match.excluded || match.confirmed ? 'default' : 'warning'}`}
          key={match.heading}
        >
          <div className="panel-heading">
            <div className="panel-title">{match.heading}</div>
          </div>

          <div className="panel-body">
            <div className="row">
              <div className="column col-md-3">
                <p>
                  <label>Matched Field</label>
                </p>

                <p>
                  <select
                    className="form-control"
                    name="field-select"
                    style={{
                      borderColor: !match.field && !match.excluded && 'red',
                      color: match.excluded && 'lightgray',
                    }}
                    value={match.field && match.field.key}
                    onChange={(e) => onFieldChange(match, index, e.target.value)}
                  >
                    <option value={null}>Select Field...</option>
                    {fields.map((field, findex) => (
                      <option value={field.key} key={findex}>
                        {field.label}
                      </option>
                    ))}
                  </select>
                </p>
              </div>

              <div className="column col-md-3">
                <p>
                  <label>Example Value</label>
                </p>
                <p>{match.value}</p>
              </div>

              <div className="column col-md-3">
                <p>
                  <button
                    className={'btn ' + (match.excluded && 'btn-danger')}
                    onClick={() => onIgnoreChange(match, index)}
                  >
                    Exclude from Import
                  </button>
                </p>
              </div>

              <div className="column col-md-3">
                {!!match.field && (
                  <p>
                    <button
                      className={'btn ' + (match.confirmed && 'btn-success')}
                      onClick={() => onConfirmChange(match, index)}
                    >
                      Confirm Field Selection
                    </button>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CsvParserFieldMatcher;
