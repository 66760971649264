import React from 'react';

import Comparable from './comparable';

import * as ss from 'simple-statistics';

export default class Comparables extends React.Component {
  get comparablesPrices() {
    const prices = this.props.comparables.filter((c) => c.selected).map((c) => c.final_price);
    if (prices.length === 0) {
      return [0];
    }
    return prices;
  }

  get comparablesMean() {
    return this.toNearest100th(ss.mean(this.comparablesPrices));
  }

  get comparablesMedian() {
    return this.toNearest100th(ss.median(this.comparablesPrices));
  }

  get comparablesStandardDeviation() {
    return this.toNearest100th(ss.standardDeviation(this.comparablesPrices));
  }

  get comparablesRangeLower() {
    return this.toNearest100th(this.comparablesMean - this.comparablesStandardDeviation);
  }

  get comparablesRangeHigher() {
    return this.toNearest100th(this.comparablesMean + this.comparablesStandardDeviation);
  }

  toNearest100th(x) {
    return Math.floor(x * 100) / 100;
  }

  render() {
    return (
      <div>
        <div className="price-calculator__results affix">
          <p>
            {this.comparablesPrices.length} comparables compared. Predicted range: £
            {this.comparablesRangeLower.toLocaleString()}- £{this.comparablesRangeHigher.toLocaleString()}
            <br />
            <small>
              Median: £{this.comparablesMedian.toLocaleString()}, Mean: £{this.comparablesMean.toLocaleString()},
              Standard deviation: £{this.comparablesStandardDeviation.toLocaleString()}
            </small>
          </p>
        </div>

        <div className="price-calculator__comparables">
          {this.props.comparables.map((c) => (
            <Comparable
              {...c}
              key={c.rightmove_identifier}
              onChange={this.props.comparableChangeHandler(c.rightmove_identifier)}
            />
          ))}
        </div>
      </div>
    );
  }
}
