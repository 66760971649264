import React, { useState } from 'react';
import Papa from 'papaparse';

import CsvParserFieldMatcher from './csv_parser_field_matcher';
import CsvParserPreview from './csv_parser_preview';

const CsvParser = ({ fields, onConfirm }) => {
  const [csv, setCsv] = useState(null);
  const [matches, setMatches] = useState([]);
  const [results, setResults] = useState([]);
  const [resultsFields, setResultsFields] = useState([]);

  const generateMatches = (headings, example) => {
    return headings.map((heading) => {
      const matchedField = fields.find((field) => {
        return (
          field.label === heading || (!!field.alternates && field.alternates.includes(heading)) || field.key === heading
        );
      });

      return {
        heading,
        field: matchedField,
        value: example[heading],
        excluded: false,
        confirmed: false,
      };
    });
  };

  const generateResults = () => {
    const confirmedMatches = matches.filter(({ confirmed }) => confirmed);
    setResultsFields(confirmedMatches.map((match) => match.field));

    const items = csv.data.map((row) => {
      const item = {};
      confirmedMatches.forEach((match) => {
        item[match.field.key] = row[match.heading];
      });
      return item;
    });
    setResults(items);
  };

  const onFileChange = (event) => {
    setCsv(null);
    setMatches([]);
    setResults([]);
    setResultsFields([]);

    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        setCsv(result);
        setMatches(generateMatches(result.meta.fields, result.data[0]));
      },
    });
  };

  const onMatchesChange = (newMatches) => {
    setMatches(newMatches);
    setResults([]);
    setResultsFields([]);
  };

  const showMatcher = () => {
    return matches.length > 0;
  };

  const showPreviewButton = () => {
    return showMatcher() && !matches.some(({ confirmed, excluded }) => !confirmed && !excluded);
  };

  const showPreview = () => {
    return results.length > 0;
  };

  return (
    <div>
      <p>
        <input type="file" allow=".csv" onChange={onFileChange} />
      </p>

      {showMatcher() && <CsvParserFieldMatcher fields={fields} initialMatches={matches} onChange={onMatchesChange} />}

      {showPreviewButton() && (
        <p>
          <button className="btn" onClick={() => generateResults()}>
            Preview Results
          </button>
        </p>
      )}

      {showPreview() && (
        <>
          <CsvParserPreview data={results} fields={resultsFields} />
          <p>
            <button className="btn" onClick={() => onConfirm(results)}>
              Confirm
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default CsvParser;
