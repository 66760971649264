import React, { Component, Fragment } from 'react';
import ImageUploadFileInput from '../forms/image_upload_file_input';
import HttpRequestor from '~/utils/http_requestor';

class ViewingExperienceFileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bucket: null,
      key: null,
      message: null,
      warning: null,
      error: false,
    };
  }

  render() {
    return (
      <Fragment>
        <ImageUploadFileInput
          id="video_tour_file"
          label="Video tour"
          name="property[viewing_experience]"
          signedUrlEndpoint={this.props.signedUrlEndpoint}
          mimeType="video/mp4"
          noPreview={true}
          beforeUpload={this.onBeforeUpload}
          afterUpload={this.onAfterUpload}
        ></ImageUploadFileInput>
        <div className="help-block">
          Need help? See our{' '}
          <a href="https://bit.ly/2LXRhOq" target="_blank">
            Video Tours guide
          </a>
        </div>
        {this.state.warning && this.renderMessage(this.state.warning, 'info')}
        {this.state.message && this.renderMessage(this.state.message, 'success')}
        {this.state.error && this.renderError()}
      </Fragment>
    );
  }

  renderMessage = (message, type = 'info') => {
    return <div className={`alert alert-${type}`}>{message}</div>;
  };

  renderError = () => {
    const canRetry = this.state.bucket && this.state.key;
    return (
      <div className="alert alert-danger">
        {this.state.error}
        {canRetry && this.renderRetry()}
      </div>
    );
  };

  renderRetry = () => {
    return (
      <div>
        <a href="#" onClick={this.retryTrigger}>
          Retry
        </a>
      </div>
    );
  };

  onBeforeUpload = () => {
    this.setState({
      message: null,
      warning: 'Do not close this page whilst the video uploads',
      error: null,
      bucket: null,
      key: null,
    });

    if (this.props.onUpload) {
      this.props.onUpload();
    }
  };

  onAfterUpload = (data) => {
    console.log(data);
    this.setState(
      {
        bucket: data.bucket,
        key: data.key,
      },
      () => {
        this.triggerVideoProcessingEvent({
          bucket: data.bucket,
          key: data.key,
        });
      }
    );

    if (this.props.onUploadComplete) {
      this.props.onUploadComplete(data);
    }
  };

  retryTrigger = (e) => {
    e.preventDefault();
    console.log('retry');
    this.setState(
      {
        error: null,
        message: 'Retrying...',
      },
      () => {
        this.triggerVideoProcessingEvent({
          bucket: this.state.bucket,
          key: this.state.key,
        });
      }
    );
  };

  triggerVideoProcessingEvent = (data) => {
    const requestor = new HttpRequestor();
    const payload = {
      bucket: data.bucket,
      key: data.key,
    };

    requestor
      .call(this.props.updateUrl, 'POST', payload)
      .then((_response) => {
        console.debug('Triggered processing job');
        this.setState({ warning: null, message: 'Video uploaded successfully' });
      })
      .catch((e) => {
        console.error(e.message);
        console.error(e);

        this.setState({ message: null, warning: null, error: 'Failed to trigger processing job' });
      });
  };
}

export default ViewingExperienceFileInput;
