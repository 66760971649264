import 'core-js/stable';
import 'regenerator-runtime/runtime';

import WebpackerReact from 'webpacker-react';
import Turbolinks from 'turbolinks';

import RoomList from 'components/room_list';
import UtilityList from 'components/utilities_list';
import PropertyCertificateList from 'components/property_certificate_list';
import PropertyCertificateForm from 'components/property_certificate_form';
import PropertyHighlightList from 'components/property_highlight_list';
import PropertyContactList from 'components/property_contact_list';
import AccessCodeList from 'components/access_code_list';
import PropertyImageList from 'components/property_image_list';
import RightmoveIdSection from 'components/rightmove_id_section';
import InPersonViewingsPossible from 'components/properties/in_person_viewings_possible';

import PriceCalculator from 'components/price_calculator/index';

import ServiceNotes from 'components/service_notes';

import ServiceForm from 'components/service_form';
import OfferAssignee from 'components/offer_assignee';
import PaperworkProgress from 'components/paperwork_progress';

import ImageUpload from 'components/forms/image_upload';
import ImageUploadFileInput from 'components/forms/image_upload_file_input';
import ViewingExperience from 'components/viewing_experience/viewing_experience';

import EmploymentStatus from 'components/employment_status';
import IncomeSource from 'components/income_source';

import Search from 'components/search';

import bindReactTurbolinksDespud from 'utils/react-turbolinks';

import EmbeddedSigningButton from 'components/embedded_signing_button';
import TermsCollection from 'components/terms_collection';

import ImageGallery from 'components/image_gallery';

import UserList from 'components/user_list';

import OwnerLeads from 'components/owner_leads/owner_leads';
import OwnerLeadCell from 'components/owner_leads/owner_lead_cell';

import ClientSignatoriesList from 'components/client_signatories/client_signatories_list';

import DeleteOrganisationPortfolio from 'components/organisations/delete_organisation_portfolio';

import CsvParser from 'components/csv_parser/csv_parser';
import CsvParserFieldMatcher from 'components/csv_parser/csv_parser_field_matcher';
import CsvParserPreview from 'components/csv_parser/csv_parser_preview';
import DataImporter from 'components/data_importer/data_importer';
import ImportManager from 'components/data_importer/import_manager';
import ImportStatusIndicator from 'components/data_importer/import_status_indicator';

Turbolinks.start();

WebpackerReact.setup({
  // Properties
  RoomList,
  UtilityList,
  PropertyCertificateList,
  PropertyCertificateForm,
  PropertyHighlightList,
  PropertyContactList,
  AccessCodeList,
  PropertyImageList,
  RightmoveIdSection,
  InPersonViewingsPossible,

  // Data Imports
  CsvParser,
  CsvParserFieldMatcher,
  CsvParserPreview,
  DataImporter,
  ImportManager,
  ImportStatusIndicator,

  // Price Calculator
  PriceCalculator,

  // Services
  ServiceNotes,
  ServiceForm,

  // Stays
  OfferAssignee,
  EmbeddedSigningButton,
  TermsCollection,
  PaperworkProgress,

  // General
  ImageUpload,
  ImageUploadFileInput,
  ViewingExperience,
  Search,

  // Users
  EmploymentStatus,
  IncomeSource,
  UserList,

  ImageGallery,

  // Owner leads
  OwnerLeads,
  OwnerLeadCell,

  // Client Signatories
  ClientSignatoriesList,

  // Organisations
  DeleteOrganisationPortfolio,
});

bindReactTurbolinksDespud();
