import React from 'react';
import API from '~/utils/owner_leads_api';
import OwnerLeadCell from '~/components/owner_leads/owner_lead_cell';

export default class OwnerLeads extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ownerLeads: [] };
    this.api.index().then((response) => {
      this.setState({ ownerLeads: response.owner_leads })
    });
  }

  render() {
    return (
      <div className="owner-leads">
        <div className="owner-leads__header col-xs-12 clearfix">
          <h1 className="owner-leads__title pull-left">
            Owner leads
          </h1>
          <button className="owner-leads__add-button btn btn-xs btn-success pull-left" onClick={this.onCreate}>
            + Add Owner Lead
          </button>
        </div>
        <table className="owner-leads__table table table-striped">
          <thead>
            <tr>
              <th className="owner-lead-cell owner-lead-cell--id">
                #
              </th>
              <th className="owner-lead-cell owner-lead-cell--property-address">
                Property address
              </th>
              <th className="owner-lead-cell">
                Floorplan text
              </th>
              <th className="owner-lead-cell owner-lead-cell--listed-at">
                Listed at
              </th>
              <th className="owner-lead-cell owner-lead-cell--zone">
                Zone
              </th>
              <th className="owner-lead-cell owner-lead-cell--bedrooms">
                Bedrooms
              </th>
              <th className="owner-lead-cell owner-lead-cell--price">
                Price
              </th>
              <th className="owner-lead-cell owner-lead-cell--summary">
                Summary
              </th>
              <th className="owner-lead-cell owner-lead-cell--postcode">
                Postcode
              </th>
              <th className="owner-lead-cell owner-lead-cell--rightmove-identifier">
                Rightmove Listing
              </th>
              <th className="owner-lead-cell owner-lead-cell--epc-graph">
                EPC
              </th>
              <th className="owner-lead-cell owner-lead-cell--floorplan-url">
                Floorplan
              </th>
              <th className="owner-lead-cell owner-lead-cell--owner-company-name">
                Owner company name
              </th>
              <th className="owner-lead-cell owner-lead-cell--owner-title">
                Owner title (Mr/Mrs/Miss)
              </th>
              <th className="owner-lead-cell owner-lead-cell--owner-name">
                Owner name
              </th>
              <th className="owner-lead-cell">
                Owner address
              </th>
              <th className="owner-lead-cell owner-lead-cell--owner-companies-house-url">
                Owner Companies House page
              </th>
              <th className="owner-lead-cell">
                Owner LinkedIn
              </th>
              <th className="owner-lead-cell">
                Owner email
              </th>
              <th className="owner-lead-cell">
                Owner phone
              </th>
              <th className="owner-lead-cell">
                Owner Hubspot contact
              </th>
              <th className="owner-lead-cell">
                Contacted at
              </th>
              <th className="owner-lead-cell">
                Responded at
              </th>
              <th className="owner-lead-cell">
                Viewing booked for
              </th>
              <th className="owner-lead-cell">
                Last updated by
              </th>
              <th className="owner-lead-cell">
                Notes
              </th>
              <th className="owner-lead-cell owner-lead-cell--actions"></th>
            </tr>
          </thead>
          <tbody>
            {this.renderRows()}
          </tbody>
        </table>
      </div>
    );
  }

  renderRows() {
    return this.state.ownerLeads.map((ownerLead) => {
      return (
        <tr key={ownerLead.id}>
          <OwnerLeadCell name="id">
            {ownerLead.id}
          </OwnerLeadCell>

          <OwnerLeadCell type="textarea" value={ownerLead.property_address} name="property_address" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="textarea" value={ownerLead.floorplan_transcription} name="floorplan_transcription" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="datetime-local" value={ownerLead.listed_at} name="listed_at" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="number" value={ownerLead.zone} name="zone" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="number" value={ownerLead.bedrooms} name="bedrooms" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="number" value={ownerLead.price} name="price" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="textarea" value={ownerLead.summary} name="summary" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="text" value={ownerLead.postcode} name="postcode" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell
            type="url"
            value={ownerLead.rightmove_identifier}
            name="rightmove_identifier"
            url={(value) => `https://www.rightmove.co.uk/property-to-rent/property-${value}.html`}
            {...this.cellParams(ownerLead)}
          />

          <OwnerLeadCell type="url" value={ownerLead.epc_graph} name="epc_graph" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="url" value={ownerLead.floorplan_url} name="floorplan_url" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="text" value={ownerLead.owner_company_name} name="owner_company_name" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="text" value={ownerLead.owner_title} name="owner_title" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="text" value={ownerLead.owner_name} name="owner_name" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="text" value={ownerLead.owner_address} name="owner_address" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="url" value={ownerLead.owner_companies_house_url} name="owner_companies_house_url" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="url" value={ownerLead.owner_linkedin} name="owner_linkedin" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="email" value={ownerLead.owner_email} name="owner_email" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="tel" value={ownerLead.owner_phone} name="owner_phone" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell>
            <small className="owner-lead-cell__help">Waiting for owner email</small>
          </OwnerLeadCell>

          <OwnerLeadCell type="datetime-local" value={ownerLead.contacted_at} name="contacted_at" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="datetime-local" value={ownerLead.responded_at} name="responded_at" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell type="datetime-local" value={ownerLead.viewing_booked_at} name="viewing_booked_at" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell name="updated">
            {this.formatDatetime(ownerLead.updated_at)}
            <small className="owner-lead-cell__help">{ownerLead.updated_by_staff_name || '-'}</small>
          </OwnerLeadCell>

          <OwnerLeadCell type="textarea" value={ownerLead.notes} name="notes" {...this.cellParams(ownerLead)}/>

          <OwnerLeadCell name="actions">
            <button className="btn btn-danger btn-xs" onClick={this.onDelete(ownerLead.id)}>
              Archive
            </button>
          </OwnerLeadCell>
        </tr>
      );
    });
  }

  onCreate = async (e) => {
    const response = await this.api.create();
    let ownerLeads = this.state.ownerLeads.slice(0);
    ownerLeads.unshift(response.owner_lead);
    this.setState({ ownerLeads });
  }

  onDelete = (id) => {
    return async () => {
      let ownerLeads = this.state.ownerLeads.filter((ownerLead) => {
        return !ownerLead.id || ownerLead.id !== id;
      });
      this.setState({ ownerLeads });
      await this.api.delete(id);
    }
  }

  onOwnerLeadChanged = (id, ownerLead) => {
    let ownerLeads = this.state.ownerLeads.slice(0);
    const index = ownerLeads.findIndex(ownerLead => ownerLead.id === id);
    ownerLeads[index] = ownerLead;
    this.setState({ ownerLeads });
  }

  cellParams = (ownerLead) => {
    return {
      id: ownerLead.id,
      token: this.props.token,
      onOwnerLeadChanged: this.onOwnerLeadChanged
    };
  }

  formatDatetime = (datetime) => {
    return new Date(datetime).toLocaleString();
  }

  get api() { return new API(this.props.token); }
}
