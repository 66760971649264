import React from 'react';
import PropertyHighlight from './property_highlight';
import ResidentFacing from './layouts/resident_facing';
import InputSection from './layouts/input_section_header';

export default class PropertyHighlightList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property_highlights: props.property_highlights === undefined ? [] : props.property_highlights,
    };
  }

  render() {
    const children = this.state.property_highlights.map((property_highlight, i) => {
      return (
        <div className="react-list__item" key={i}>
          <ResidentFacing>
            <PropertyHighlight highlightIndex={i} details={property_highlight} />
          </ResidentFacing>
        </div>
      );
    });

    return (
      <InputSection title={I18n.t('properties.form.property_highlight_list_heading')}>
        <div className="property-highlights react-list">{children}</div>
        <small className="pull-left">{this._renderAddButton()}</small>
      </InputSection>
    );
  }

  _renderAddButton() {
    return (
      <button onClick={this.addPropertyHighlight} className="btn btn-primary">
        {I18n.t('properties.form.highlights.add')}
      </button>
    );
  }

  addPropertyHighlight = (event) => {
    event.preventDefault();
    this.setState({ property_highlights: [...this.state.property_highlights, {}] });
  };
}
