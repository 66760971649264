export default class API {
  constructor(token) {
    this.token = token;
  }

  index = async () => {
    const response = await fetch('/owner_leads', {
      method: 'GET',
      headers: this.headers
    });
    return await response.json();
  }

  create = async (attrs = {}) => {
    const response = await fetch('/owner_leads', {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({ 'owner_lead': attrs })
    });
    return await response.json();
  }

  update = async (id, attrs = {}) => {
    const response = await fetch(`/owner_leads/${id}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({ 'owner_lead': attrs })
    });
    return await response.json();
  }

  delete = async (id) => {
    const response = await fetch(`/owner_leads/${id}`, {
      method: 'DELETE',
      headers: this.headers
    });
    return await response.json();
  }

  get headers() {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': this.token
    };
  }
}
