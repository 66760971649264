import React from 'react';
import { currencyCodeToSymbol } from '~/utils/currency';
import { joinClasses } from '~/utils/react_utils';

export default class MoneyInput extends React.Component {
  render() {
    const { priceSuffix, helpText, label, errors, useProRataUpfrontPayment, ...inputProps } = this.props;
    const classes = joinClasses(['form-group', 'money', this.props.errors && this.props.required && 'has-error']);

    return (
      <div className={classes}>
        <label className="control-label" htmlFor={this.props.name}>
          {this.props.required && <abbr title="required">* </abbr>}
          {this.props.label}
        </label>
        <div>
          <div className="input-group col-sm-12">
            <span className="input-group-addon">{currencyCodeToSymbol(this.props.currency)}</span>
            <input
              step=".01"
              className="numeric decimal form-control"
              type="number"
              id={this.props.name}
              {...inputProps}
              defaultValue={this._value}
            />
            {priceSuffix && <span className="input-group-addon">{priceSuffix}</span>}
          </div>
          {useProRataUpfrontPayment && (
            <div className="alert alert-info" role="alert">Upfront rent pro-rata based on Organisation</div>
          )}
          {helpText && <p className="help-block">{helpText}</p>}
        </div>
      </div>
    );
  }

  get _value() {
    return this.props.defaultValue || '0.00';
  }
}
