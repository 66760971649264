import React from 'react';

export default class Submit extends React.Component {
  render() {
    return (
      <input
        type="submit"
        name="commit"
        value={this.props.label}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        className={`btn btn-primary ${this.props.className ? this.props.className : ''}`}
      />
    );
  }
}
