import React from 'react'

export default class BooleanDropdown extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="form-group">
        <label className="control-label">{this.props.componentLabel}</label>
        <select onChange={this.props.onChange} defaultValue={this.props.defaultValue} name={this.props.componentName} className="form-control">
          <option value="">Select an option:</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
    )
  }
}
