import React, { Component } from 'react';

export default class ViewingSearchItem extends Component {
  componentDidUpdate() {
    if (this.props.active === 'active') {
      this.props.scrollIntoView(this.searchResult);
    }
  }

  renderState() {
    const { item } = this.props;

    switch (item.state) {
      case 'new':
        return <span className="label label-info">New</span>;
      case 'happened':
        return <span className="label label-success">Happened</span>;
      case 'started':
        return <span className="label label-info">Started</span>;
      case 'no_show':
        return <span className="label label-default">No Show</span>;
      case 'cancelled':
        return <span className="label label-default">Cancelled</span>;
      default:
        return null;
    }
  }

  renderGuide() {
    const { item } = this.props;

    if (!item.guide) {
      return null;
    }

    return (
      <div className="search-bar__result-guide">
        <img className="search-bar__result-guide-image" src={item.guide.image} />
        <p>{item.guide.name}</p>
      </div>
    );
  }

  render() {
    const { item, active } = this.props;

    return (
      <div ref={(result) => (this.searchResult = result)} className="search-bar__result">
        <a href={item.url} className={`list-group-item ${active}`}>
          <p className="search-bar__result-id">Viewing #{item.id}</p>

          <h4 className="search-bar__result-heading list-group-item-heading">
            <p className="search-bar__result-title">{item.title}</p>
          </h4>

          {this.renderGuide()}

          <p>{item.start_time}</p>

          <div class="search-bar__result-viewing-details">
            {this.renderState()}
            {item.viewing_event && <span className="label label-info">Viewing Event</span>}
          </div>
        </a>
      </div>
    );
  }
}
