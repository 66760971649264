import React from 'react';
import API from '~/utils/owner_leads_api';
import { joinClasses } from '~/utils/react_utils';

export default class OwnerLeadCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    }
  }

  render() {
    return (
      <td className={this.classes}>
        <div className="owner-lead-cell__inner">
          {this.showTextInput && <input
            type={this.props.type}
            className="owner-lead-cell__input"
            name={this.inputName}
            onChange={this.onChange}
            value={this.state.value || ''}
          />}
          {this.showTextArea && <textarea
            className="owner-lead-cell__textarea"
            name={this.inputName}
            onChange={this.onChange}
            value={this.state.value || ''}
            rows={3}
          />}
          {this.showLink &&
            <a
              className="owner-lead-cell__link btn btn-xs btn-default"
              href={this.href}
              target="_blank"
            >
              Visit
            </a>
          }
          {this.showMailTo &&
            <a className="owner-lead-cell__link btn btn-xs btn-default" href={`mailto:${this.state.value}`} target="_blank">
              Email
            </a>
          }
          {this.props.children}
        </div>
      </td>
    );
  }

  onChange = (e) => {
    this.setState({ value: e.target.value }, async () => {
      const params = {};
      params[this.props.name] = this.state.value;
      const response = await this.api.update(this.props.id, params);
      this.props.onOwnerLeadChanged(this.props.id, response.owner_lead);
    });
  }

  get api() { return new API(this.props.token); }

  get showTextArea() { return this.props.type === 'textarea'; }

  get showTextInput() {
    return ['url','email','text','tel','number','datetime-local'].includes(this.props.type);
  }

  get showLink() { return this.props.type === 'url' && this.state.value; }

  get showMailTo() { return this.props.type === 'email' && this.state.value; }

  get classes() {
    return joinClasses([
      'owner-lead-cell',
      this.props.type && `owner-lead-cell--${this.props.type}`,
      this.props.name && `owner-lead-cell--${this.props.name.replace(/_/g, '-')}`,
      this.props.class
    ])
  }

  get href() {
    if (this.props.url) {
      return this.props.url(this.state.value);
    }
    return this.state.value;
  }
}
