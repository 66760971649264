import React, { Component, Fragment } from 'react';
import { hot } from 'react-hot-loader';

import ViewingExperienceFileInput from './viewing_experience_file_input';
import HttpRequestor from '~/utils/http_requestor';
import ViewingExperiencePreview from './viewing_experience_preview';

class ViewingExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: null,
      processing: false,
    };
  }

  componentDidMount() {
    this.loadPreview();
  }

  render() {
    return (
      <Fragment>
        <div>
          <ViewingExperienceFileInput
            signedUrlEndpoint={this.props.signedUrlEndpoint}
            updateUrl={this.props.updateUrl}
            onUpload={this.onUpload}
            onUploadComplete={this.onUploadComplete}
          />
        </div>

        <div>{this.renderPreview()}</div>
      </Fragment>
    );
  }

  renderPreview() {
    if (this.state.processing) {
      return this.renderProcessingPreview();
    } else if (this.state.preview === true) {
      return <ViewingExperiencePreview objects={this.state.previewObjects} />;
    } else if (this.state.preview === false) {
      return this.renderNoPreview();
    } else {
      return this.renderPendingPreview();
    }
  }

  renderNoPreview() {
    return (
      <div className="panel panel-info">
        <div className="panel-heading">No video added</div>
        <div className="panel-body">
          Upload a single, full-length MP4 video tour downloaded from the camera.
          <br />
          <i>Max Size: 5 GB</i>
        </div>
      </div>
    );
  }

  renderProcessingPreview() {
    return <div className="alert alert-info">Processing uploaded video, you can now close this window.</div>;
  }

  renderPendingPreview() {
    return <div className="alert alert-info">Loading...</div>;
  }

  onUpload = () => {
    this.setState({ processing: false });
    window.onbeforeunload = (e) => {
      e = e || window.event;
      if (e) {
        e.returnValue = 'Closing this page will cancel the current upload';
      }
      return 'Closing this page will cancel the current upload';
    };
  };

  onUploadComplete = () => {
    this.setState({ processing: true });
    this.loadPreview(300);

    window.onbeforeunload = () => {
      return null;
    };
  };

  loadPreview(delay = 0) {
    setTimeout(() => {
      this.getPreview();
    }, delay * 1000);
  }

  getPreview() {
    const requestor = new HttpRequestor();
    requestor.call(this.props.previewUrl, 'GET').then((response) => {
      if (response.length) {
        this.setState({
          processing: false,
          preview: true,
          previewObjects: response,
        });
      } else {
        this.setState({
          preview: false,
          previewObjects: null,
        });
        if (this.state.processing) {
          this.loadPreview(10);
        }
      }
    });
  }
}

export default hot(module)(ViewingExperience);
