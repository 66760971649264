import React, { Component } from 'react';
import User from './user';

export default class UserList extends Component {
  constructor(props) {
    super(props);

    const initialResident = this.newResidentId;

    this.state = {
      users: [initialResident],
      leadResident: initialResident,
    };
  }

  render() {
    return (
      <div className="user-list">
        <div className="row">{this.renderUsers()}</div>
        <a className="btn btn-info pull-right" href="#" onClick={this.addResident}>
          Add Resident +
        </a>
      </div>
    );
  }

  renderUsers = () => {
    return this.state.users.map((id) => (
      <User
        key={id}
        residentId={id}
        isLeadResident={this.checkIfLead(id)}
        onDelete={this.onDelete}
        updateLeadResident={this.updateLeadResident}
        emailValidationPattern={this.props.emailValidationPattern}
      />
    ));
  };

  // Provides a unique id to represent a user
  get newResidentId() {
    return Date.now();
  }

  checkIfLead = (id) => {
    return this.state.leadResident == id;
  };

  addResident = (e) => {
    const { users } = this.state;
    users.push(this.newResidentId);
    this.setState({ users });
  };

  updateLeadResident = (id) => {
    this.setState({ leadResident: id });
  };

  onDelete = (id) => {
    let users = this.state.users;
    const idIndex = users.indexOf(id);

    users.splice(idIndex, 1);

    // If last resident is deleted, replace with a blank one
    if (users.length == 0) {
      users.push(this.newResidentId);
    }

    let updatedState = {
      users,
    };

    // If the lead resident gets deleted, set whoever is left as the new lead.
    if (id == this.state.leadResident) {
      updatedState.leadResident = users[0];
    }

    this.setState(updatedState);
  };
}
