export const copyEventTargetText = (event) => {
  event.target.select();
  document.execCommand('copy');
};

// From https://stackoverflow.com/a/901144
export const queryParameter = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const baseURL = () => {
  return window.location.href.split('?')[0];
};

export const titleize = (string) => {
  const lowercaseize = (match, string) => string.toLowerCase();
  const capitalize = (match, letter) => letter.toUpperCase();

  return string
    .replace(/\b([A-Z]+)/g, lowercaseize) // Switch all capital words - eg LONDON - to lowercase
    .replace(/\b(\w)/g, capitalize); // Capitalize words
};
