import React from 'react';

import Select from '~/components/forms/select';

export default class InPersonViewingsPossible extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showReason: !this.props.viewings_possible,
      originalViewingsPossible: this.props.viewings_possible,
      newViewingsPossibleValue: this.props.viewings_possible, // defaults to on load value
    };
  }

  viewingsPossibleUpdated = (value) => {
    this.setState({
      showReason: !this.state.showReason,
      newViewingsPossibleValue: value,
    });
  };

  renderReasonField = () => {
    if (!this.state.showReason) {
      return null;
    }
    return (
      <Select
        label="Please provide a reason"
        name="property[viewings_not_possible_reason]"
        includeBlank={true}
        required={this.viewingsBeingTurnedOff}
        options={[
          ['Property under maintenance', 'under_maintenance'],
          ['Application submitted / approved', 'application_submitted_or_approved'],
          ['Previous tenants / owner currently in-situ', 'previous_tenants_or_owner_in_situ'],
          ['Acquired with residents in-situ', 'acquired_with_residents_in_situ'],
          ['Let by another agent', 'let_by_another_agent'],
          ['No longer marketed by us', 'no_longer_marketed_by_us'],
        ]}
        defaultValue={this.props.viewings_not_possible_reason}
      />
    );
  };

  render() {
    return (
      <div>
        <Select
          label="In-person viewings possible"
          name="property[viewings_possible]"
          options={[
            ['Yes', 'true'],
            ['No', 'false'],
          ]}
          includeBlank={true}
          defaultValue={this.props.viewings_possible}
          helpText="Properties marked as viewable will show on AllDayPA forms"
          onChange={(event) => this.viewingsPossibleUpdated(event.target.value)}
        />
        {this.renderReasonField()}
      </div>
    );
  }

  // the reason is only required if we are turning viewings off, otherwise all old
  // properties where viewings possible is 'no' would not be able to be updated.
  get viewingsBeingTurnedOff() {
    return this.state.originalViewingsPossible && this.state.newViewingsPossibleValue == 'false';
  }
}
