import React from 'react';
import Bathroom from './rooms/bathroom';
import Bedroom from './rooms/bedroom';
import Kitchen from './rooms/kitchen';
import ReceptionRoom from './rooms/reception_room';
import Terrace from './rooms/terrace';
import Delete from './forms/delete';
import ResidentFacing from './layouts/resident_facing';

const ROOMS = [
  { value: 'bathrooms', label: 'Bathroom' },
  { value: 'bedrooms', label: 'Bedroom' },
  { value: 'kitchens', label: 'Kitchen' },
  { value: 'reception_rooms', label: 'Reception Room' },
  { value: 'terraces', label: 'Terrace' }
];

export default class Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.details.type === undefined ? '' : props.details.type,
      details: props.details,
      roomIndex: props.roomIndex,
      deleteReady: false
    }
  }

  render() {
    const addRoomContent = this.state.type.length > 0 ? this._renderAddRoom() : ''
    return (
      <div className={`property-room react-list__item ${this.deleteItemClass()}`}>
        <ResidentFacing>
          {this._renderSelectType()}
          {addRoomContent}
        </ResidentFacing>
      </div>
    )
  }

  setType = (event) => {
    this.setState({
      type: event.target.value
    })
  }

  setDeleteReady = () => {
    this.setState({deleteReady: !this.state.deleteReady})
  }

  deleteItemClass() {
    return this.state.deleteReady ? 'for-delete' : ''
  }

  _renderSelectType() {
    return (
      <div className="form-group type-select">
        <select value={this.state.type} onChange={this.setType} className="form-control type-select-dropdown" required>
          <option value="">Select an option:</option>
          { this._renderTypeOptions() }
        </select>

        <Delete name={`property[rooms_attributes][${this.state.roomIndex}][delete]`} onChange={this.setDeleteReady}/>
      </div>
    )
  }

  _renderTypeOptions() {
    return ROOMS.map((action) => {
      return (
        <option key={action.value} value={action.value}>{action.label}</option>
      )
    })
  }

  _renderAddRoom() {
    switch (this.state.type) {
      case 'bathrooms':       return (<Bathroom roomIndex={this.state.roomIndex} details={this.state.details} />); break;
      case 'bedrooms':        return (<Bedroom roomIndex={this.state.roomIndex} details={this.state.details} />); break;
      case 'kitchens':        return (<Kitchen roomIndex={this.state.roomIndex} details={this.state.details} />); break;
      case 'reception_rooms': return (<ReceptionRoom roomIndex={this.state.roomIndex} details={this.state.details} />); break;
      case 'terraces':        return (<Terrace roomIndex={this.state.roomIndex} details={this.state.details} />); break;
    }
  }
}
