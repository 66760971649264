import React, { useState, useEffect } from 'react';
import axios from 'axios';

const useDataImportWatcher = ({
  id,
  status: initialStatus,
  review_count: initialReviewCount,
  results_count: initialResultsCount,
  refresh = 2000,
}) => {
  const [status, setStatus] = useState(initialStatus);
  const [reviewCount, setReviewCount] = useState(initialReviewCount);
  const [resultsCount, setResultsCount] = useState(initialResultsCount);

  const statusesToMonitor = ['pending', 'processing'];

  useEffect(() => {
    const request = {
      headers: {
        Accept: 'application/json',
      },
      method: 'GET',
      url: `/data_imports/${id}`,
      params: {
        'fields[data_import]': 'status,review_count,results_count',
      },
    };

    // Only start polling if it's a status that is going to change
    if (statusesToMonitor.includes(status)) {
      const intervalId = setInterval(() => {
        axios(request).then((response) => {
          const { status: newStatus, review_count, results_count } = response.data.attributes;

          // Update need review count
          setReviewCount(review_count);
          setResultsCount(results_count);

          // If the status has changed then update the status
          // and check if we need to stop polling
          if (newStatus != status) {
            setStatus(newStatus);
            if (!statusesToMonitor.includes(newStatus)) {
              clearInterval(intervalId);
            }
          }
        });
      }, refresh);

      return () => clearInterval(intervalId);
    }
  }, [id, status]);

  // Return the live values
  return {
    status,
    reviewCount,
    resultsCount,
  };
};

export default useDataImportWatcher;
