import React from 'react';
import AccessCode from './access_code';
import ResidentFacing from './layouts/resident_facing';
import InputSection from './layouts/input_section_header';

export default class AccessCodeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessCodes: props.access_codes === undefined ? [] : props.access_codes,
    };
  }

  render() {
    const children = this.state.accessCodes.map((accessCode, i) => {
      return (
        <div className="react-list__item" key={i}>
          <ResidentFacing>
            <AccessCode key={i} accessCodeIndex={i} details={accessCode} offerUsers={this.props.offerUsers} />
          </ResidentFacing>
        </div>
      );
    });

    return (
      <InputSection title="Access Codes">
        <div className="access-code react-list">{children}</div>
        <small className="pull-left">{this._renderAddButton()}</small>
      </InputSection>
    );
  }

  _renderAddButton() {
    return (
      <button onClick={this.addAccessCode} className="btn btn-primary btn-default">
        + Add Access Code
      </button>
    );
  }

  addAccessCode = (event) => {
    event.preventDefault();
    this.setState({ accessCodes: [...this.state.accessCodes, {}] });
  };
}
