import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { joinClasses } from '~/utils/react_utils';

const STAY_STEP = 'stay_step';
const PRE_PAPERWORK_STEP = 'pre_paperwork_step';
export default class PaperworkProgress extends Component {
  render() {
    return <div className="paperwork-progress">{this.renderProgressSteps()}</div>;
  }

  renderProgressSteps = () => {
    return this.props.paperworkSections.map((section) => {
      if (section.type == STAY_STEP) {
        return <Step key={section.title} {...section} />;
      } else if (section.type == PRE_PAPERWORK_STEP) {
        return <PrePaperworkStep key={section.resident} {...section} />;
      } else {
        return <ResidentStep key={section.resident} {...section} />;
      }
    });
  };
}

function Step({ title, completed }) {
  const classes = joinClasses([
    'stay-step',
    'progress-step',
    completed && 'progress-step--completed',
    !completed && 'progress-step--incomplete',
  ]);

  return (
    <div className={classes}>
      <h1 className="progress-step__section-title">{title}</h1>
    </div>
  );
}

function ResidentStep({ resident, steps, completed }) {
  const classes = joinClasses([
    'progress-step',
    'progress-step',
    completed && 'progress-step--completed',
    !completed && 'progress-step--incomplete',
  ]);

  const renderedSteps = steps.map((step, index) => {
    const substepClasses = joinClasses([
      'progress-step__substep',
      step.completed && 'progress-step__substep--completed',
      !step.completed && 'progress-step__substep--incomplete',
    ]);

    return <li key={index} className={substepClasses}>{step.title}</li>;
  });

  return (
    <div className={classes}>
      <h1 className="progress-step__section-title">{resident}</h1>
      <ul className="progress-step__substeps">{renderedSteps}</ul>
    </div>
  );
}

function PrePaperworkStep({ title, steps, completed }) {
  const classes = joinClasses([
    'progress-step',
    'progress-step',
    completed && 'progress-step--completed',
    !completed && 'progress-step--incomplete',
  ]);

  const renderedSteps = steps.map((step, index) => {
    const substepClasses = joinClasses([
      'progress-step__substep',
      step.completed && 'progress-step__substep--completed',
      !step.completed && 'progress-step__substep--incomplete',
    ]);

    return <li key={index} className={substepClasses}>{step.title}</li>;
  });

  return (
    <div className={classes}>
      <h1 className="progress-step__section-title">{title}</h1>
      <ul className="progress-step__substeps">{renderedSteps}</ul>
    </div>
  );
}

PaperworkProgress.propTypes = {
  paperworkSections: PropTypes.arrayOf(PropTypes.object),
};

PaperworkProgress.defaultProps = {
  paperworkSections: [],
};
