import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { joinClasses } from '~/utils/react_utils';

export default class Input extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.handleChange && this.props.handleChange(event);
  }

  render() {
    const classes = joinClasses([
      'form-group',
      this.props.className && this.props.className,
      this.props.errors && this.props.required && 'has-error',
    ]);

    return (
      <div className={classes}>
        {this.props.label != null && (
          <label className="control-label" htmlFor={this.htmlId}>
            {this.props.required && <abbr title="required">* </abbr>}
            {this.props.label}
          </label>
        )}
        {this._renderInput()}
        {this._renderHint()}
      </div>
    );
  }

  _renderInput() {
    if (this.props.addOnBefore != null || this.props.addOnAfter != null) {
      return (
        <Fragment>
          <div className="input-group">
            {this._renderAddon(this.props.addOnBefore)}
            {this._renderField()}
            {this._renderAddon(this.props.addOnAfter)}
          </div>
          {this.props.helpText && <p className="help-block">{this.props.helpText}</p>}
        </Fragment>
      );
    } else {
      return this._renderField();
    }
  }

  _renderField() {
    return (
      <input
        type={this.props.type || 'text'}
        className={this.inputClasses}
        name={this.props.name}
        id={this.htmlId}
        defaultValue={this.props.defaultValue}
        defaultChecked={this.props.defaultChecked}
        required={this.props.required ? 'required' : false}
        onChange={this.handleChange}
        accept={this.props.accept}
        checked={this.props.checked}
        pattern={this.props.pattern}
        disabled={this.props.disabled}
        {...this.props.additionalParams}
      />
    );
  }

  _renderAddon(addOnContent) {
    if (addOnContent != null) {
      return <span className="input-group-addon">{addOnContent}</span>;
    }
  }

  _renderHint() {
    if (!this.props.hint) {
      return;
    }
    return <p className="help-block">{this.props.hint}</p>;
  }

  get htmlId() {
    if (this.props.id) {
      return this.props.id;
    }
    return this.props.name.replace(/\W/g, '_');
  }

  get inputClasses() {
    return joinClasses(['form-control', this.props.inputClasses]);
  }
}

Input.propTypes = {
  hint: PropTypes.string,
};
