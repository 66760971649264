import React from 'react';

import SearchExtension from './search/search_extension';
import PropertySearchItem from './search/property_search_item';
import UserSearchItem from './search/user_search_item';
import ViewingSearchItem from './search/viewing_search_item';
import OrganisationSearchItem from './search/organisation_search_item';
import PortfolioSearchItem from './search/portfolio_search_item';

import Omnibar from 'omnibar';

export default class Search extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="search-bar">
        <div ref={(list) => (this.searchWrapper = list)} className="list-group">
          <Omnibar
            className="omnibar-search__input"
            placeholder="Search...."
            extensions={[SearchExtension]}
            render={this.SearchItem}
            inputDelay={450}
          />
        </div>
      </div>
    );
  }

  SearchItem = ({ item, isSelected, isHighlighted }) => {
    const active = isSelected || isHighlighted ? 'active' : '';

    const props = {
      key: item.key,
      item: item,
      active,
      scrollIntoView: this.scrollIntoView,
    };

    if (item.model === 'property') {
      return <PropertySearchItem {...props} />;
    }

    if (item.model === 'user') {
      return <UserSearchItem {...props} />;
    }

    if (item.model === 'viewing') {
      return <ViewingSearchItem {...props} />;
    }

    if (item.model === 'organisation') {
      return <OrganisationSearchItem {...props} />;
    }

    if (item.model === 'portfolio') {
      return <PortfolioSearchItem {...props} />;
    }
  };

  scrollIntoView = (result) => {
    const parent = this.searchWrapper.getElementsByTagName('ul')[0];

    parent.scrollTop = result.offsetTop - 150;
  };
}
