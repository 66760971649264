import React, { Component } from 'react';
import Input from './forms/input';
import Delete from './forms/delete';
import Checkbox from './forms/checkbox';

export default class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      mobile: '',
    };
  }

  render() {
    return (
      <div className="user-list__user col-sm-4">
        <Delete id={this.props.residentId} name="users[][delete]" onChange={this.onDelete} />
        <Input
          handleChange={this.onChange('firstName')}
          className="required"
          name={`users[][first_name]`}
          label="First name"
          required={true}
          value={this.state.firstName}
        />
        <Input
          handleChange={this.onChange('middleName')}
          name={`users[][middle_name]`}
          label="Middle name"
          required={false}
          value={this.state.middleName}
        />
        <Input
          handleChange={this.onChange('lastName')}
          className="required"
          name={`users[][last_name]`}
          label="Last name"
          required={true}
          value={this.state.lastName}
        />
        <Input
          handleChange={this.onChange('email')}
          className="required"
          name={`users[][email]`}
          label="Email"
          required={true}
          value={this.state.email}
          type="email"
          pattern={this.props.emailValidationPattern}
        />
        <Input
          handleChange={this.onChange('mobile')}
          className="required"
          name={`users[][mobile]`}
          label="Mobile"
          required={false}
          value={this.state.mobile}
        />
        <Checkbox
          handleChange={this.onChange('leadResident')}
          label="Lead resident?"
          defaultChecked={this.props.isLeadResident}
          value={this.props.isLeadResident}
          name="users[][lead_resident]"
          type="checkbox"
        />
      </div>
    );
  }

  onDelete = () => {
    this.props.onDelete(this.props.residentId);
  };

  onChange = (stateName) => (e) => {
    const targetValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState(
      {
        [stateName]: targetValue,
      },
      () => {
        if (stateName == 'leadResident') {
          this.props.updateLeadResident(this.props.residentId);
        }
      }
    );
  };
}
