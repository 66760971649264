module.exports = [
  {
    label: 'Our Stay ID',
    key: 'id',
    alternates: ['ID', 'Residently ID'],
  },
  {
    label: 'Mngmt Org Stay ID',
    key: 'management_organisation_stay_identifier',
    alternates: ['Reference', 'Tenancy Reference'],
  },
  {
    label: 'Our Property ID',
    key: 'property_id',
    alternates: ['Property ID', 'Residently Property ID'],
  },
  {
    label: 'Mngmt Org Property ID',
    key: 'management_organisation_property_identifier',
    alternates: ['Unit Reference'],
  },
  {
    label: 'Start Date',
    key: 'start_date',
  },
  {
    label: 'End Date',
    key: 'end_date',
  },
  {
    label: 'Final Lease End Date',
    key: 'final_lease_end_date',
    alternates: ['Termination Date'],
  },
  {
    label: 'Our Accomodation Price',
    key: 'our_accomodation_price',
  },
  {
    label: 'Total Upfront Rent',
    key: 'total_upfront_rent',
  },
  {
    label: 'Upfront Rent in Months',
    key: 'upfront_rent_months',
  },
  {
    label: 'Residents Break Date',
    key: 'residents_break_date',
  },
  {
    label: 'Residents Break Notice (in months)',
    key: 'residents_break_notice_in_months',
  },
  {
    label: 'Payment Day of Month',
    key: 'payment_day_of_month',
  },
  {
    label: 'Selected Package',
    key: 'selected_package',
  },
  {
    label: 'Total Price In Pounds',
    key: 'total_price_in_pounds',
  },
  {
    label: 'Free Cleaning Duration (in weeks)',
    key: 'free_cleaning_duration_weeks',
  },
  {
    label: 'Security Deposit Amount In Pounds',
    key: 'security_deposit_amount_in_pounds',
  },
  {
    label: 'Upfront Rent Amount In Pounds',
    key: 'upfront_rent_amount_in_pounds',
  },
  {
    label: 'Original Price In Pounds',
    key: 'original_price_in_pounds',
  },
  {
    label: 'Original End Date',
    key: 'original_end_date',
  },
  {
    label: 'Our Break Date',
    key: 'our_break_date',
  },
  {
    label: 'Our Break Notice (in months)',
    key: 'our_break_notice_in_months',
  },
  {
    label: 'Subsequent Payment Method',
    key: 'subsequent_payment_method',
    type: 'select',
    options: [
      { value: 'bank_transfer', label: 'Bank Transfer' },
      { value: 'paid_in_advance', label: 'Paid in Advance' },
      { value: 'stripe', label: 'Stripe' },
      { value: 'cheque', label: 'Cheque' },
    ],
  },
  {
    label: 'Insitu Started At',
    key: 'in_situ_start',
  },
  {
    label: 'Approved At',
    key: 'approved_at',
  },
  {
    label: 'Show Residents Set Up Rent Payments In App',
    key: 'requires_billing_onboarding',
    type: 'checkbox',
  },
];
