import React from 'react';

export default class PropertyInputs extends React.Component {
  render() {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="price-calculator__sqft" className="col-sm-5 control-label">
            Area
          </label>
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              id="price-calculator__sqft"
              value={this.props.area}
              onChange={this.onChangeHandler('area')}
            />
            <div className="input-group-addon">sqft</div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="price-calculator__sqft-range" className="col-sm-5 control-label">
            Area range
          </label>
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              id="price-calculator__sqft-range"
              value={this.props.area_range}
              onChange={this.onChangeHandler('area_range')}
            />
            <div className="input-group-addon">sqft</div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="price-calculator__bedrooms" className="col-sm-5 control-label">
            Beds
          </label>
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              id="price-calculator__bedrooms"
              value={this.props.bedrooms}
              onChange={this.onChangeHandler('bedrooms')}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="price-calculator__property-type" className="col-sm-5 control-label">
            Type
          </label>
          <div className="input-group">
            <select
              className="form-control"
              id="price-calculator__property-type"
              value={this.props.property_type}
              onChange={this.onChangeHandler('property_type')}
            >
              <option value="">Select property type</option>
              <option value="flat">Flat</option>
              <option value="house">House</option>
            </select>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="price-calculator__floor" className="col-sm-5 control-label">
            Floor
          </label>
          <div className="input-group">
            <select
              className="form-control"
              id="price-calculator__floor"
              value={this.props.floor_number}
              onChange={this.onChangeHandler('floor_number')}
            >
              <option value="">Select a floor number...</option>
              <option value="-1">Basement</option>
              <option value="0">Ground floor</option>
              <option value="1">1st floor</option>
              <option value="2">2nd floor</option>
              <option value="3">3rd floor</option>
              <option value="4">4th floor</option>
              <option value="5">5th floor</option>
              <option value="6">6th floor or higher</option>
            </select>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="price-calculator__furnish-type" className="col-sm-5 control-label">
            Furnishing
          </label>
          <div className="input-group">
            <select
              className="form-control"
              id="price-calculator__furnish-type"
              value={this.props.furnish_type}
              onChange={this.onChangeHandler('furnish_type')}
            >
              <option value="">Select furnishing</option>
              <option value="unfurnished">Unfurnished</option>
              <option value="furnished">Furnished</option>
            </select>
          </div>
        </div>
      </div>
    );
  }

  onChangeHandler(k) {
    return (e) => this.props.onChange({ [k]: e.target.value });
  }
}
