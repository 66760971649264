import React, { Component, Fragment } from 'react';
import Contact from './contact';

export default class PropertyContactList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyContacts: props.propertyContacts,
    };
  }

  render() {
    return (
      <Fragment>
        <div className={`contacts contacts__${this.props.contactType} clearfix`}>
          <div className="contacts__list">{this.renderContacts()}</div>
          <button className="btn btn-primary contacts__add-button pull-left" type="button" onClick={this.onAddContact}>
            + Add {this.props.label}
          </button>
        </div>
      </Fragment>
    );
  }

  renderContacts = () => {
    return this.state.propertyContacts.map((contact, index) => {
      const contact_props = {
        contacts: this.props.contacts,
        label: ``,
        name: `property[property_contacts][${this.props.contactType}][${index}]`,
        contactType: this.props.contactType,
      };

      if (contact.id) {
        contact_props.selectedId = contact.contact.id;
        contact_props.propertyContactId = contact.id;
      }

      return <Contact key={index} {...contact_props} />;
    });
  };

  onAddContact = (e) => {
    this.setState({
      propertyContacts: [...this.state.propertyContacts, {}],
    });
  };
}
