import React from 'react'
import Input from './forms/input'
import Delete from './forms/delete'

export default class PropertyHighlight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: props.details.attributes === undefined ? '' : props.details.attributes,
      highlightId: props.details.id === undefined ? '' : props.details.id,
      highlightIndex: props.highlightIndex,
      deleteReady: false
    }
  }

  render() {
    const namePrefix = `property[property_highlights][${this.state.highlightIndex}]`;

    return (
      <div className={`highlight ${this.deleteItemClass()}`}>
        <input className="hidden" type="hidden" value={this.state.highlightId} name={`${namePrefix}[id]`}/>

        <div className="form-group type-select">
          <Delete name={`property[property_highlights][${this.state.highlightIndex}][delete]`} onChange={this.setDeleteReady}/>
        </div>

        <Input label="Room" name={`${namePrefix}[title]`} defaultValue={this.state.attributes.title} additionalParams={{required: 'required'}} />
        <Input name={`${namePrefix}[point_1]`} defaultValue={this.state.attributes.point_1} additionalParams={{required: 'required', placeholder: "Highlight 1"}} addOnBefore="&middot;"/>
        <Input name={`${namePrefix}[point_2]`} defaultValue={this.state.attributes.point_2} additionalParams={{placeholder: "Highlight 2"}} addOnBefore="&middot;"/>
      </div>
    )
  }

  setDeleteReady = () => {
    this.setState({deleteReady: !this.state.deleteReady})
  }

  deleteItemClass() {
    return this.state.deleteReady ? 'for-delete' : ''
  }
}
