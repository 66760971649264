import React, { Component, Fragment } from 'react';
import PropertyImage from './property_image';
import Dropzone from 'react-dropzone';
import merge from 'lodash/merge';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import move from 'lodash-move';

export default class PropertyImageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.addListKeys(props.images),
      uploading: false,
    };
  }

  render() {
    return (
      <Fragment>
        {this.renderList()}
        <Dropzone
          onDrop={this.onDrop.bind(this)}
          className="well property-image-dropzone"
          activeClassName="property-image-dropzone-active"
        >
          <p className="property-image-dropzone__text">{this.props.dropzoneCaption}</p>
          <span className="glyphicon glyphicon-upload property-image-dropzone__icon"></span>
        </Dropzone>
      </Fragment>
    );
  }

  onDrop = (acceptedFiles) => {
    this.setState({ images: this.appendNewImages(acceptedFiles) });
  };

  appendNewImages = (newFiles) => {
    return [
      ...this.state.images,
      ...newFiles.map((file) => ({ id: null, listKey: Math.random(), attributes: { file } })),
    ];
  };

  renderList = () => {
    return (
      <SortableList
        items={this.state.images}
        onSortEnd={this.onSortEnd}
        shouldCancelStart={this.shouldCancelStart}
        property_id={this.props.property_id}
        uploadable_id={this.props.uploadable_id}
        uploadable_type={this.props.uploadable_type}
        uploading={this.state.uploading}
        onImageUpdate={this.onImageUpdate}
        newPropertyImageUrl={this.props.newPropertyImageUrl}
        authenticityToken={this.props.authenticityToken}
        displayCaptions={this.props.displayCaptions}
        axis="xy"
      />
    );
  };

  onImageUpdate = (index, updates, callback) => {
    const newImages = this.state.images.slice(0);
    for (let i = 0; i < newImages.length; i++) {
      if (i === index) {
        const updatedImage = merge({}, this.state.images[i], updates);
        newImages[i] = updatedImage;
      }
    }

    // images still uploading will not have an id yet
    const uploading = newImages.some((element) => {
      return element.id == null;
    });

    this.setState(
      {
        images: newImages,
        uploading: uploading,
      },
      callback
    );
  };

  addListKeys = (items) => {
    return items.map((item) => {
      item.listKey = Math.random();
      return item;
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let newImages = this.state.images.slice(0);
    newImages = move(newImages, oldIndex, newIndex);
    this.setState({ images: newImages });
  };

  shouldCancelStart = (e) => {
    if (['input', 'textarea', 'select', 'option', 'label'].indexOf(e.target.tagName.toLowerCase()) !== -1) {
      return true; // Return true to cancel sorting
    }
  };
}

const SortableItem = SortableElement((props) => {
  return <PropertyImage {...props} />;
});

const SortableList = SortableContainer(
  ({
    items,
    property_id,
    uploadable_id,
    uploadable_type,
    uploading,
    onImageUpdate,
    newPropertyImageUrl,
    authenticityToken,
    displayCaptions,
  }) => {
    return (
      <div className="property-featureset react-list">
        {items.map((image, index) => (
          <SortableItem
            key={image.listKey}
            index={index}
            itemIndex={index}
            image={image}
            uploading={uploading}
            file={image.file}
            property_id={property_id}
            uploadable_id={uploadable_id}
            uploadable_type={uploadable_type}
            onImageUpdate={onImageUpdate}
            newPropertyImageUrl={newPropertyImageUrl}
            authenticityToken={authenticityToken}
            displayCaption={displayCaptions}
          />
        ))}
      </div>
    );
  }
);
