import React from 'react';

export default class Form extends React.Component {
  render() {
    return (
      <form action={this.props.action} method="POST" {...this.restProps}>
        <input type="hidden" name="_method" value={this.props.method} />
        <input type="hidden" name="authenticity_token" value={this.props.authenticityToken} />
        {this.props.children}
      </form>
    );
  }

  get restProps() {
    const { method, authenticityToken, ...restProps } = this.props;
    return restProps;
  }
}
