import React from 'react';
import PropTypes from 'prop-types';

export default class Input extends React.Component {
  render() {
    return (
      <div className={`form-group ${this.props.className || ''}`}>
        <div className="checkbox">
          <label className="control-label" htmlFor={this.props.id}>
            {this._renderInput()}
            {this.props.label}
          </label>
          {this._renderHint()}
        </div>
      </div>
    );
  }

  _renderInput() {
    if (this.props.addOnBefore != null || this.props.addOnAfter != null) {
      return (
        <div className="input-group">
          {this._renderAddon(this.props.addOnBefore)}
          {this._renderField()}
          {this._renderAddon(this.props.addOnAfter)}
        </div>
      );
    }
    return this._renderField();
  }

  _renderField() {
    const {
      className,
      hint,
      label,
      handleChange,
      ...fieldProps
    } = this.props;
    return <input type="checkbox" className="boolean" onChange={this.props.handleChange} {...fieldProps} />;
  }

  _renderAddon(addOnContent) {
    if (addOnContent != null) {
      return <span className="input-group-addon">{addOnContent}</span>;
    }
  }

  _renderHint() {
    if (!this.props.hint) {
      return;
    }
    return <p className="help-block">{this.props.hint}</p>;
  }
}

Input.propTypes = {
  hint: PropTypes.string,
};
