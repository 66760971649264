import React from 'react';
import { joinClasses } from '~/utils/react_utils';

export default class Select extends React.Component {
  render() {
    const classes = joinClasses(['form-group', 'select', this.props.errors && this.props.required && 'has-error']);

    return (
      <div className={classes}>
        <label className="control-label" htmlFor={this.props.name}>
          {this.props.required && <abbr title="required">* </abbr>}
          {this.props.label}
        </label>
        <select
          className="form-control select"
          id={this.props.name}
          {...this._selectProps}
          {...this.props.additionalParams}
        >
          {this.props.includeBlank ? <option value=""></option> : null}
          {this._renderOptions()}
        </select>
        {this.props.disabled && this.props.disabledText && <div className="alert alert-info" role="alert">{this.props.disabledText}</div>}
        {this.props.helpText && <p className="help-block">{this.props.helpText}</p>}
      </div>
    );
  }

  _renderOptions = () => {
    return this.props.options.map(this._renderOption);
  };

  _renderOption([label, value], index) {
    return (
      <option key={`${label}_${value}_${index}`} value={value}>
        {label}
      </option>
    );
  }

  get _selectProps() {
    let props = { ...this.props };
    delete props.includeBlank;
    delete props.helpText;
    delete props.errors;
    return props;
  }
}
