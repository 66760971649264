import React, { Component } from 'react';
import 'whatwg-fetch';
import axios from 'axios';
import { forOwn, includes } from 'lodash';
import PropTypes from 'prop-types';
import { joinClasses } from '~/utils/react_utils';

export default class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
      previewUrl: null,
      uploading: false,
      deleteImage: false,
    };
  }

  render() {
    const imagePreviewClasses = [
      'image-upload__preview',
      'preview-image',
      this.state.deleteImage && 'image-upload__preview--marked-deletion',
    ];

    return (
      <div className="image-upload">
        <input className="image-upload__value" name={this.props.name} type="hidden" value={this.inputImageUrl} />
        {this.props.children}
        {this.state.text && <p className="image-upload__text">{this.state.text}</p>}
        {this.showPreviewImage && (
          <div className="image-upload__preview-wrapper">
            {this.props.allowDeletion && (
              <DeleteButton active={this.state.deleteImage} onClick={this.markForDeletion} />
            )}
            <img className={joinClasses(imagePreviewClasses)} src={this.previewUrl} />
          </div>
        )}
        {this.showLink && (
          <a className="image-upload__link" href={this.previewUrl} target="_blank">
            Download PDF
          </a>
        )}
        {this.showClearWarning && (
          <p className="help-block">Saving without adding a new file will clear the uploaded file</p>
        )}
      </div>
    );
  }

  componentDidMount = () => {
    if (this.props.image && !this.props.image.id) {
      this.uploadFile(this.props.file);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.file && this.props.file !== prevProps.file) {
      this.uploadFile(this.props.file);
    }
  }

  markForDeletion = (e) => {
    this.setState({
      deleteImage: !this.state.deleteImage,
    });
  };

  fetchSignedUrl = (file) => () => {
    return fetch(this.signedUrlEndpoint(file), {
      method: 'GET',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }).then((response) => response.json());
  };

  postToSignedUrl = (file) => (createResponse) => {
    return axios({
      method: 'post',
      url: createResponse.postUrl,
      data: this.postToSignedUrlArgs(file, createResponse),
      onUploadProgress: this.progressUpload,
    })
      .then((response) => response.data)
      .then((body) => {
        const data = this.extractUploadedResponseData(body);
        this.setState({ previewUrl: data.url });
        return data;
      });
  };

  postToSignedUrlArgs = (file, response) => {
    let data = new FormData();
    forOwn(response.postFields, function (value, key) {
      data.append(key, value);
    });
    data.append('file', file);
    return data;
  };

  extractUploadedResponseData = (body) => {
    const url = body.match(/<Location>(.*?)<\/Location>/)[1];
    const bucket = body.match(/<Bucket>(.*?)<\/Bucket>/)[1];
    const key = body.match(/<Key>(.*?)<\/Key>/)[1];
    return {
      bucket: bucket,
      key: key,
      url: decodeURIComponent(url),
    };
  };

  uploadFile = (file) => {
    this.startUpload()
      .then(this.beforeUpload)
      .then(this.fetchSignedUrl(file))
      .then(this.postToSignedUrl(file))
      .then(this.afterUpload)
      .then(this.finishUpload)
      .catch(this.handleError);
  };

  startUpload = () => {
    this.toggleSubmitButton(false);
    this.setState({
      text: 'Uploading...',
      previewUrl: null,
      uploading: true,
    });
    return Promise.resolve();
  };

  beforeUpload = () => {
    if (this.props.beforeUpload) {
      return this.props.beforeUpload();
    }
    return Promise.resolve();
  };

  progressUpload = (event) => {
    const percent = Math.floor((event.loaded / event.total) * 100);
    this.setState({
      text: `Uploading... ${percent}%`,
      previewUrl: null,
      uploading: true,
    });
  };

  afterUpload = (data) => {
    if (this.props.afterUpload) {
      return this.props.afterUpload(data);
    }
    return Promise.resolve();
  };

  finishUpload = () => {
    this.toggleSubmitButton(true);
    const uploadComplete = this.props.noPreview ? 'Upload complete 😄' : null;
    this.setState({
      text: uploadComplete,
      uploading: false,
    });
  };

  withCacheBuster(url) {
    return url + '?' + new Date().getTime();
  }

  fileExtension(file) {
    return file.name.split('.').pop();
  }

  signedUrlEndpoint = (file) => {
    return this.props.signedUrlEndpoint + '?file_extension=' + this.fileExtension(file);
  };

  handleError = (e) => {
    this.setState({
      text: 'Upload failed 😰',
      uploading: false,
    });
    console.error(e);
    this.toggleSubmitButton(true);
    return e;
  };

  toggleSubmitButton = (toggle) => {
    if (this.props.uploading) {
      return;
    }
    const button = document.getElementById('submit-button');
    if (button) {
      button.disabled = !toggle;
    }
  };

  get previewUrl() {
    if (this.state.uploading) {
      return '';
    }
    if (this.state.previewUrl) {
      return this.withCacheBuster(this.state.previewUrl);
    }
    if (this.props.initialProcessedUrl) {
      return this.withCacheBuster(this.props.initialProcessedUrl);
    }
    if (this.props.initialUrl) {
      return this.withCacheBuster(this.props.initialUrl);
    }
    return '';
  }

  get inputImageUrl() {
    if (this.state.deleteImage) {
      return '';
    }
    if (this.state.previewUrl) {
      return this.state.previewUrl;
    }
    if (this.props.initialUrl) {
      return this.props.initialUrl;
    }
    return '';
  }

  get showPreviewImage() {
    return !this.props.noPreview && this.previewUrl && this.isSupportedImage;
  }

  get showClearWarning() {
    return this.props.showClearWarning;
  }

  get showLink() {
    return !this.props.noPreview && this.previewUrl && includes(this.previewUrl, '.pdf');
  }

  get isSupportedImage() {
    return ['.png', '.jpg', '.heic'].some((fileExtension) => {
      return this.previewUrl.toLowerCase().includes(fileExtension);
    });
  }
}

const DeleteButton = ({ active, onClick }) => {
  const classes = [
    'image-upload__delete-icon',
    'fa',
    'fa-trash',
    'fa-2x',
    active && 'image-upload__delete-icon--active',
  ];

  return <i className={joinClasses(classes)} aria-hidden="true" onClick={onClick}></i>;
};

ImageUpload.propTypes = {
  signedUrlEndpoint: PropTypes.string,
  initialUrl: PropTypes.string,
  initialProcessedUrl: PropTypes.string,
  beforeUpload: PropTypes.func,
  afterUpload: PropTypes.func,
  image: PropTypes.object,
  noPreview: PropTypes.bool,
};
