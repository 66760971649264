import React, { Component } from 'react';
import FileInput from './file_input';
import ImageUpload from './image_upload';
import 'whatwg-fetch';

export default class ImageUploadFileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  render() {
    return (
      <ImageUpload
        label={this.props.label}
        name={this.props.name}
        initialUrl={this.props.initialUrl}
        signedUrlEndpoint={this.props.signedUrlEndpoint}
        beforeUpload={this.props.beforeUpload}
        afterUpload={this.props.afterUpload}
        file={this.state.file}
        noPreview={this.props.noPreview}
        showClearWarning={this.props.showClearWarning || false}
        allowDeletion={this.props.allowDeletion}
      >
        <FileInput
          {...this.passThroughProps}
          name=""
          className="image-upload__input"
          inputClasses={this.props.className}
          accept={this.mimeType}
          handleChange={this.onFileChange}
        />
      </ImageUpload>
    );
  }

  get mimeType() {
    if (this.props.mimeType) {
      return this.props.mimeType;
    }
    return 'image/jpg';
  }

  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({ file });
  };

  get passThroughProps() {
    const {
      signedUrlEndpoint,
      initialUrl,
      imageUrl,
      beforeUpload,
      afterUpload,
      name,
      noPreview,
      allowDeletion,
      ...otherProps
    } = this.props;
    return otherProps;
  }
}
