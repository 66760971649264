import React from 'react'
import Input from './forms/input'
import Delete from './forms/delete'

export default class AccessCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: props.details.attributes === undefined ? '' : props.details.attributes,
      accessCodeId: props.details.id === undefined ? '' : props.details.id,
      accessCodeIndex: props.accessCodeIndex,
      deleteReady: false
    }
  }

  render() {
    const namePrefix = `property[access_codes][${this.state.accessCodeIndex}]`;

    return (
      <div className={`access-code ${this.deleteItemClass()}`}>
        <input className="hidden" type="hidden" value={this.state.accessCodeId} name={`${namePrefix}[id]`}/>

        <Delete name={`property[access_codes][${this.state.accessCodeIndex}][delete]`} onChange={this.setDeleteReady}/>

        <Input label="Title" name={`${namePrefix}[title]`} defaultValue={this.state.attributes.title} required={true} />
        <Input label="Door Code" name={`${namePrefix}[code]`} defaultValue={this.state.attributes.code} />
        <Input label="Description" name={`${namePrefix}[description]`} defaultValue={this.state.attributes.description} />

        <div className="form-group">
          <label className="control-label">
            Resident
          </label>
          <select className="form-control" name={`${namePrefix}[offer_user_id]`} defaultValue={this.state.attributes.offer_user_id}>
            <option value="">All residents</option>
            {this.renderUserOptions()}
          </select>
        </div>
      </div>
    )
  }

  setDeleteReady = () => {
    this.setState({deleteReady: !this.state.deleteReady})
  }

  deleteItemClass() {
    return this.state.deleteReady ? 'for-delete' : ''
  }

  renderUserOptions = () => {
    return this.props.offerUsers.map((offerUser) => {
      return <option value={offerUser.id} key={offerUser.id}>{offerUser.fullName}</option>
    });
  }
}
