import 'whatwg-fetch';

export default class HttpRequestor {
  constructor({ dispatchError = () => {}, suppressErrors = false } = {}) {
    this.nextCsrfToken = null;
    this.dispatchError = dispatchError;
    this.suppressErrors = suppressErrors;
  }

  call(url, method, body = {}) {
    let args = {
      method: method,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
      },
      body: body,
      credentials: 'same-origin',
    };

    if (~['POST', 'PATCH', 'PUT', 'DELETE'].indexOf(method)) {
      const token = this.csrfToken();
      args.body['authenticity_token'] = token;
      args.headers['X-CSRF-Token'] = token;
      args.body = JSON.stringify(args.body);
    }

    if (~['GET', 'HEAD'].indexOf(method)) {
      delete args.body;
    }

    return fetch(url, args)
      .then(this.handleResponseErrors)
      .then(this.handleResponse)
      .catch(this.handleFetchError);
  }

  handleResponseErrors = (response) => {
    if (this.suppressErrors) {
      return false;
    }
    if (!response.ok) {
      const json = response.json();
      json
        .then((data) => {
          if (data['redirect_url']) {
            logger.warn('Response returned redirect');
            location.assign(data['redirect_url']);
          } else {
            // Not a redirect, something else went wrong.
            this.dispatchError(data);
          }
        })
        .catch((error) => {
          // Not JSON response, something else went wrong.
          this.dispatchError(error);
        });
      return Promise.reject(json);
    }
    return response;
  };

  handleResponse = (response) => {
    if (response.status == 204) {
      return null;
    }
    return response.json();
  };

  handleFetchError = (error) => {
    if (this.suppressErrors) {
      return false;
    }
    this.dispatchError(error);
    return Promise.reject(error);
  };

  csrfToken() {
    const csrf_element = document.querySelector('meta[name=csrf-token]');
    const token = this.nextCsrfToken || (csrf_element && csrf_element.getAttribute('content'));
    return token;
  }
}
