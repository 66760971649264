import React, { Component, Fragment } from 'react';
import HttpRequestor from '~/utils/http_requestor';

export default class RightmoveIdSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rightmoveIds: this.props.rightmoveIds,
      rightmoveId: '',
      isValid: true,
      submitting: false,
      errorMessage: '',
    };
    this.http = new HttpRequestor();
  }
  render() {
    return (
      <Fragment>
        <label className="control-label string optional">Rightmove ID(s)</label>
        {this.state.rightmoveIds.map((rmId) => (
          <p key={rmId}>
            <strong>{rmId}</strong>
          </p>
        ))}
        <Fragment>
          <div className="rightmove-id-section__input-group input-group">
            <span className="input-group-addon">{this.rightmovePrefix}</span>
            <input
              id="rightmove-id-section__input"
              className="form-control rightmove-id-section__input"
              value={this.state.rightmoveId}
              onChange={this.handleInputChange}
            />
          </div>
          {this.state.errorMessage && (
            <div className="alert alert-danger" role="alert">
              <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
              <span className="sr-only">Error:</span>
              {this.state.errorMessage}
            </div>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.handleUploadIdentifier}
            disabled={this.state.submitting}
          >
            Add Rightmove Identifier
          </button>
        </Fragment>
        <p className="help-block">
          Our branch id followed by the reference entered by us on Rightmove - eg 68253_1234. A property in some cases
          can have multiple Rightmove IDs. All IDs in this list will work correctly.
        </p>
      </Fragment>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      rightmoveId: e.target.value,
    });
  };

  handleUploadIdentifier = () => {
    this.setState({
      submitting: true,
      isValid: true,
    });

    const data = {
      ref_id: this.state.rightmoveId,
      source: 'rightmove',
      property_id: this.props.propertyId,
    };

    this.http
      .call(this.props.portalListingsEndpoint, 'POST', data)
      .then((response) => {
        if (response) {
          this.setState({
            submitting: false,
            rightmoveIds: this.state.rightmoveIds.concat([response.data.attributes.ref_id]),
            rightmoveId: '',
            errorMessage: '',
          });
        }
      })
      .catch(() => {
        this.setState({
          errorMessage: 'Something went wrong, please try again.',
        });
      });
  };

  get rightmovePrefix() {
    return `${this.props.residentlyRightmoveId}_${this.props.propertyId}_`;
  }
}
