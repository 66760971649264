import React, { Component } from 'react';
import prettyBytes from 'pretty-bytes';

class ViewingExperiencePreview extends Component {
  render() {
    return this.renderObjects();
  }

  renderObjects() {
    return this.props.objects.map((object) => {
      return (
        <p key={object.key}>
          <a href={object.url}>{object.key}</a>
          <br />
          <b>Size</b>: {prettyBytes(object.size)} <b>Updated</b>: {this.formatDate(object.updated_at)}
        </p>
      );
    });
  }

  formatDate(date) {
    const parsed_date = new Date(date);
    return parsed_date.toString();
  }
}

export default ViewingExperiencePreview;
