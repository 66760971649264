import React, { Component, Fragment } from 'react';
import Form from './forms/form';
import Select from './forms/select';
import MoneyInput from './forms/money_input';
import Integer from './forms/integer';
import Input from './forms/input';
import Date from './forms/date';
import DateTime from './forms/date_time';
import Submit from './forms/submit';
import EndOfTenancyCleaningFields from './services/end_of_tenancy_cleaning_fields';

export default class ServiceForm extends Component {
  constructor(props) {
    super(props);

    const { original_price_in_pence, price_in_pence } = this.props.service;

    this.state = {
      serviceType: this.props.service.service_type,
      free: this.props.service.free,
      originalPriceInPence: original_price_in_pence ? original_price_in_pence / 100 : null,
      priceInPence: price_in_pence ? price_in_pence / 100 : null,
      selectedPackageId: this.props.service.package_id,
      selectedPackageName: this.props.service.package_name,
    };
  }

  render() {
    return (
      <Form action={this.props.action} method={this.props.method} authenticityToken={this.props.authenticityToken}>
        <div className="form-group">
          {this._serviceType()}
          {this._serviceProvider()}
        </div>

        {this._renderResidentlyMoveWarning()}

        <div className="form-group">
          {this._renderFreeCheckbox()}
          {!this.hideDefaultPrice && this._originalPriceInPounds()}
          {!this.hideDefaultPrice && this._priceInPounds()}
          {this._quantity()}
          {this._showQuantityAndTotalPrice() && this._renderTotalPrice()}
          {this._upfrontAmountInPounds()}
        </div>

        <div className="form-group">
          {this._startDate()}
          {this._paidStartDate()}
          {this._effectiveCancellationDate()}
        </div>

        <div className="form-group">
          {this._ironingHoursPerVisit()}
          {this._timeInterval()}
        </div>

        <div className="form-group">{this._userNote()}</div>

        {this._isCleaningService() && this._renderCleaningFields()}
        {this.state.serviceType === 'end_of_tenancy_cleaning' && (
          <EndOfTenancyCleaningFields service={this.props.service} />
        )}
        {this.state.serviceType === 'airbnb' && this._renderAirbnbFields()}
        {this.state.serviceType === 'furniture' && this._renderFurnitureFields()}
        {this.state.serviceType === 'moving' && this._renderMovingFields()}

        <input type="hidden" name="redirect" defaultValue={this.props.redirect} />

        <Submit label={I18n.t('offer_services.form.submit')} />
      </Form>
    );
  }

  _showQuantityAndTotalPrice = () => {
    return this.props.service.quantity != null;
  };

  _serviceType = () => {
    if (this.props.method === 'POST') {
      return (
        <Select
          label={I18n.t('offer_services.form.service_type')}
          name="offer_service[service_type]"
          options={this.props.serviceTypes}
          includeBlank={this.props.method == 'POST'}
          defaultValue={this.state.serviceType}
          onChange={this._onServiceTypeChange}
        />
      );
    }
    return (
      <p className="service-form__header service-form__header--service-type">{`${I18n.t(
        'offer_services.form.service_type'
      )}: ${this.state.serviceType.replace(/_/g, ' ')}`}</p>
    );
  };

  _serviceProvider = () => {
    if (!this.props.service_provider) return null;
    return (
      <p className="service-form__label">{`${I18n.t(
        'offer_services.form.service_provider'
      )}: ${this.props.service.service_provider.replace(/_/g, ' ')}`}</p>
    );
  };

  _renderResidentlyMoveWarning() {
    if (this.state.serviceType !== 'residently_move') return null;
    if (this.props.residentlyMoveEnabled) return null;

    return (
      <div class="alert alert-warning" role="alert">
        {I18n.t('offer_services.form.residently_move_warning')}
      </div>
    );
  }

  _renderFreeCheckbox() {
    if (!this._isCleaningService()) return null;
    return (
      <Input
        className="service-free-checkbox"
        label="Free?"
        defaultValue={this.state.free}
        defaultChecked={this.state.free}
        name="offer_service[free]"
        type="checkbox"
        handleChange={this._toggleAmount}
      />
    );
  }

  _originalPriceInPounds = () => {
    return (
      <Fragment>
        <MoneyInput
          label={'Original price'}
          name="offer_service[original_price_in_pounds]"
          value={this.state.originalPriceInPence}
          onChange={(e) => this.setState({ originalPriceInPence: e.target.value })}
          priceSuffix={this._priceSuffix()}
          currency={this.props.currency}
        />
        {!this._showQuantityAndTotalPrice() && (
          <p className="help-block">{I18n.t('offer_services.form.hints.original_price')}</p>
        )}
      </Fragment>
    );
  };

  _priceInPounds = () => {
    return (
      <Fragment>
        <MoneyInput
          label={'Current price'}
          name="offer_service[price_in_pounds]"
          value={this.state.priceInPence}
          onChange={(e) => this.setState({ priceInPence: e.target.value })}
          priceSuffix={this._priceSuffix()}
          currency={this.props.currency}
        />
        {!this._showQuantityAndTotalPrice() && (
          <p className="help-block">{I18n.t('offer_services.form.hints.our_price')}</p>
        )}
      </Fragment>
    );
  };

  _upfrontAmountInPounds = () => {
    if ((!this.props.service.upfront_amount_in_pence && this.state.serviceType === 'wifi') || this.isAddonServiceType) {
      return (
        <>
          <MoneyInput
            label={`One-off`}
            name="offer_service[upfront_amount_in_pounds]"
            defaultValue={this.props.service.upfront_amount_in_pence / 100}
            currency={this.props.currency}
          />
          <p className="help-block">{I18n.t('offer_services.form.hints.upfront_amount')}</p>
        </>
      );
    }
    return null;
  };

  _startDate = () => {
    if (this.state.serviceType !== 'moving' && this.state.serviceType !== 'ironing') return null;
    return (
      <Date
        label={
          this.state.serviceType === 'moving'
            ? I18n.t('offer_services.form.moving_date')
            : I18n.t('offer_services.form.start_date')
        }
        name="offer_service[start_date]"
        defaultValue={this.props.service.start_date}
      />
    );
  };

  _paidStartDate = () => {
    if (this.props.free || !this.props.service.paid_start_date) return null;
    return (
      <Date
        label={I18n.t('offer_services.form.paid_start_date')}
        name="offer_service[paid_start_date]"
        defaultValue={this.props.service.paid_start_date}
      />
    );
  };

  _effectiveCancellationDate = () => {
    if (!this.props.service.actioned_at) return null;
    return (
      <Date
        label={I18n.t('offer_services.form.effective_cancellation_date')}
        name="offer_service[effective_cancellation_date]"
        defaultValue={this.props.service.effective_cancellation_date}
      />
    );
  };

  _quantity = () => {
    if (this.showQuantityForService || this.props.service.quantity) {
      return (
        <Integer
          label={`Quantity`}
          name="offer_service[quantity]"
          defaultValue={this.state.quantity || this.props.service.quantity}
          min={this.minimumQuantity}
          step={this.quantityStepAmount}
        />
      );
    }
    return null;
  };

  _ironingHoursPerVisit = () => {
    if (!this.props.service._ironingHoursPerVisit) return null;
    return (
      <Input
        label={I18n.t('offer_services.form.ironing_hours')}
        name="offer_service[ironing_hours_per_visit]"
        defaultValue={this.props.service.ironing_hours_per_visit}
      />
    );
  };

  _timeInterval() {
    if (
      !this.props.service.timeInterval &&
      !this._isCleaningService() &&
      !['ironing', 'flowers'].includes(this.state.serviceType)
    )
      return null;
    return (
      <Select
        label={I18n.t('offer_services.form.time_interval')}
        name="offer_service[time_interval]"
        options={this.props.timeIntervals}
        defaultValue={this.props.service.time_interval}
      />
    );
  }

  _userNote() {
    if (!this.props.service.user_note) return null;
    return (
      <Fragment>
        <label>Note from Resident</label>
        <textarea disabled className="form-control">
          {this.props.service.user_note}
        </textarea>
      </Fragment>
    );
  }

  _onServiceTypeChange = (e) => {
    if (e.target.value == 'furniture') {
      this.setState({
        originalPriceInPence: this.props.furniturePrice / 100,
        priceInPence: this.props.furniturePrice / 100,
      });
    }
    this.setState({
      serviceType: e.target.value,
      quantity: this.props.service.quantity || 1,
    });
  };

  _onPackageChange = ({ target }) => {
    let [name, id, price] = this.props.packages.find((furniturePackage) => furniturePackage[1] === target.value);
    this.setState({
      originalPriceInPence: price / 100,
      priceInPence: price / 100,
      selectedPackageId: id,
      selectedPackageName: name,
    });
  };

  _priceSuffix = () => {
    if (this._isMovingService) {
      return null;
    }

    if (this._showQuantityAndTotalPrice()) {
      return 'per quantity';
    }

    if (this._isCleaningService()) {
      return this.props.servicePriceSuffixes['cleaning'];
    }

    return this.props.servicePriceSuffixes[this.state.serviceType];
  };

  _toggleAmount = (event) => {
    this.setState({ free: event.target.checked });
  };

  _isCleaningService = () => {
    return this.props.cleaningServiceTypes.includes(this.state.serviceType);
  };

  _renderTotalPrice = () => {
    return (
      <MoneyInput
        label={I18n.t('offer_services.form.price_total')}
        name="total_price"
        defaultValue={this.props.totalPrice / 100}
        disabled="true"
        currency={this.props.currency}
      />
    );
  };

  _renderCleaningFields() {
    return (
      <Fragment>
        {this.props.service.free && (
          <Input
            label={I18n.t('offer_services.form.free_instances')}
            name="offer_service[free_instances]"
            defaultValue={this.props.service.free_instances}
          />
        )}

        <Select
          label={I18n.t('offer_services.form.cleaning_preferred_day_of_week')}
          name="offer_service[cleaning_preferred_day_of_week]"
          options={this.props.daysOfWeek}
          defaultValue={this.props.service.cleaning_preferred_day_of_week}
        />

        <Select
          label={I18n.t('offer_services.form.cleaning_preferred_time_of_day')}
          name="offer_service[cleaning_preferred_time_of_day]"
          options={this.props.timesOfDay}
          defaultValue={this.props.service.cleaning_preferred_time_of_day}
        />

        {this.props.service.free && (
          <Date
            label={I18n.t('offer_services.form.free_start_date')}
            name="offer_service[free_start_date]"
            defaultValue={this.props.service.free_start_date}
          />
        )}
        <Date
          label={I18n.t('offer_services.form.paid_start_date')}
          name="offer_service[paid_start_date]"
          defaultValue={this.props.service.paid_start_date}
        />
        {this.props.service.promotion && (
          <Input
            label={I18n.t('offer_services.form.cleaning_promotion')}
            name="offer_service[promotion]"
            defaultValue={this.props.service.promotion}
            disabled="true"
          />
        )}
      </Fragment>
    );
  }

  _renderAirbnbFields() {
    return (
      <Fragment>
        <DateTime
          label={I18n.t('offer_services.form.airbnb_leaving_at')}
          name="offer_service[airbnb_leaving_at]"
          defaultValue={this.props.service.airbnb_leaving_at}
          timezone={this.props.propertyTimezone}
        />

        <DateTime
          label={I18n.t('offer_services.form.airbnb_returning_at')}
          name="offer_service[airbnb_returning_at]"
          timezone={this.props.propertyTimezone}
          defaultValue={this.props.service.airbnb_returning_at}
        />
      </Fragment>
    );
  }

  _renderFurnitureFields() {
    return (
      <Fragment>
        <Select
          label={I18n.t('offer_services.form.package_id')}
          name="offer_service[package_id]"
          options={this.props.packages}
          defaultValue={this.state.selectedPackageId}
          includeBlank
          required
          onChange={this._onPackageChange}
        />

        <input type="hidden" name="offer_service[package_name]" value={this.state.selectedPackageName} />
        <input type="hidden" name="offer_service[quantity]" value={1} />

        {this.state.selectedPackageId && this._originalPriceInPounds()}
        {this.state.selectedPackageId && this._priceInPounds()}

        {this.props.service.landlord_owns_furniture && (
          <p className="service-form__label service-form__label">
            {I18n.t('offer_services.form.landlord_owns_furniture')}
          </p>
        )}
        <p className="service-form__label service-form__label">
          {this.props.service.terms_accepted_at
            ? `${I18n.t('offer_services.form.furniture_terms_accepted')}${this.props.service.terms_accepted_at}`
            : I18n.t('offer_services.form.furniture_terms_not_accepted')}
        </p>
      </Fragment>
    );
  }

  _renderMovingFields() {
    return (
      <Fragment>
        <MoneyInput
          label={I18n.t('offer_services.form.moving_price')}
          name="offer_service[price_in_pounds]"
          defaultValue={this.props.service.price_in_pence / 100}
          currency={this.props.currency}
        />
        <p className="help-block">
          {I18n.t('offer_services.form.hints.moving_quote', { bedrooms: this.props.service.bedrooms })}
        </p>
        {this.props.service.from_address && (
          <Input
            label={I18n.t('offer_services.form.moving_from')}
            name="offer_service[from_address]"
            defaultValue={this.props.service.from_address}
          />
        )}
        {this.props.service.to_address && (
          <Input
            label={I18n.t('offer_services.form.moving_to')}
            name="offer_service[to_address]"
            defaultValue={this.props.service.to_address}
          />
        )}
      </Fragment>
    );
  }

  get isAddonServiceType() {
    return this.state.serviceType && this.state.serviceType.includes('addon_pack_');
  }

  get showQuantityForService() {
    return (
      this.state.serviceType &&
      ['addon', 'painting', 'ironing', 'cleaning'].some((element) => {
        return this.state.serviceType.includes(element);
      })
    );
  }

  get hideDefaultPrice() {
    return (
      this.props.enableSplitPricing != 'true' ||
      (this.state.serviceType &&
        ['airbnb', 'moving', 'furniture'].some((element) => {
          return this.state.serviceType.includes(element);
        }))
    );
  }

  get minimumQuantity() {
    if (this.isAddonServiceType) {
      return 1;
    }
    return 0;
  }

  get quantityStepAmount() {
    return this._isCleaningService() ? '0.5' : '1';
  }
}
