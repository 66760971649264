import React from 'react';

import { titleize } from './utils';

export default class SearchParameters extends React.Component {
  get humanAddress() {
    if (!this.props.address) {
      return;
    }

    return titleize(this.props.address);
  }

  onChangeHandler(k) {
    return (e) => this.props.onChange({ [k]: e.target.value });
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <p className="property-address">{this.humanAddress}</p>
          <input type="text" className="form-control" id="price-calculator__address-search" placeholder="Address" />
        </div>

        <div className="form-group">
          <label htmlFor="price-calculator__radius" className="col-sm-5 control-label">
            Radius
          </label>
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              id="price-calculator__radius"
              value={this.props.radius}
              onChange={this.onChangeHandler('radius')}
            />
            <div className="input-group-addon">miles</div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="price-calculator__max-age" className="col-sm-5 control-label">
            Lising age
          </label>
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              id="price-calculator__max-age"
              value={this.props.max_age}
              onChange={this.onChangeHandler('max_age')}
            />
            <div className="input-group-addon">days</div>
          </div>
        </div>
      </div>
    );
  }
}
