import React, { Fragment, Component } from 'react';
import LightBox from 'react-images';

export default class ImageGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
      isOpen: false,
    };
  }

  render() {
    return (
      <Fragment>
        <ClickableImage onClick={this.openLightbox} image={this.props.images[0].src} />
        <LightBox
          images={this.props.images}
          isOpen={this.state.isOpen}
          currentImage={this.state.currentImage}
          onClickPrev={this.prevImage}
          onClickNext={this.nextImage}
          onClose={this.closeLightbox}
          backdropClosesModal={true}
        />
      </Fragment>
    );
  }

  openLightbox = () => {
    this.setState({
      isOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      isOpen: false,
    });
  };

  prevImage = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  nextImage = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };
}

function ClickableImage(props) {
  const style = {
    backgroundImage: `url(${props.image})`,
  };

  return (
    <div onClick={props.onClick} className="image-gallery__preview image-gallery__preview--clickable" style={style}>
      <div className="image-gallery__icon">
        <span className="glyphicon glyphicon-search"></span>
      </div>
    </div>
  );
}
