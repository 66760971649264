import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { parseISO, formatDistance } from 'date-fns';

export default class ServiceNotes extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };
  }

  _handleClose = () => {
    this.setState({ show: false });
  };

  _handleShow = () => {
    this.setState({ show: true });
  };

  _renderNotes(notes) {
    return notes.map((note) => {
      const noteTime = formatDistance(parseISO(note.created_at), new Date());
      return (
        <div key={note.id} className="service-notes--note">
          <h4>
            {note.staff_name} <small title={note.created_at}>{noteTime} ago</small>
          </h4>
          <p>&ldquo;{note.content}&rdquo;</p>
        </div>
      );
    });
  }

  _renderTitle(service) {
    return (
      <span className="service-notes--title">
        <span className="title-service">{I18n.t(`offer_services.service_type.${service.service_type}`)}</span>
        <small className="title-stay">{this.props.title}</small>
      </span>
    );
  }

  _renderAddNote() {
    const token = this._csrfToken();
    return (
      <form action={this.props.createUrl} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input type="hidden" name="note[notable_id]" value={this.props.service.id} />
        <input type="hidden" name="note[notable_type]" value="OfferService" />
        <div className="form-group">
          <label>Add note</label>
          <textarea className="form-control" name="note[content]"></textarea>
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary">
            {I18n.t('notes.add_button_text')}
          </button>
        </div>
      </form>
    );
  }

  _csrfToken() {
    const csrf_element = document.querySelector('meta[name=csrf-token]');
    return csrf_element && csrf_element.getAttribute('content');
  }

  render() {
    const service = this.props.service;

    return (
      <div>
        <Button bsStyle="primary" bsSize="small" onClick={this._handleShow}>
          Notes ({service.notes.length})
        </Button>

        <Modal show={this.state.show} onHide={this._handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this._renderTitle(service)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this._renderNotes(service.notes)}
            <hr />
            {this._renderAddNote(service)}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this._handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
