import React from 'react';

export default class Error extends React.Component {
  render() {
    return (
      <div className="error">
        <h2>Something went wrong 😢</h2>
        <p>Please contact Engineering with the following:</p>
        <textarea onFocus={(e) => copyEventTargetText(e)}>{this.props.error}</textarea>
        <button className="btn btn-error" onClick={this.props.onClose}>
          × Close
        </button>
      </div>
    );
  }
}
