import React from 'react';

export default class Alert extends React.Component {
  render() {
    let listItems;

    if(this.props.items){
      const shownItems = this.props.items.filter((item) => item.show);
      if(!shownItems.length){
        return null;
      }

      if (shownItems.length == 1){
        listItems = shownItems[0].message;
      } else{
        listItems = (<ul className='alert__list'>
          {shownItems.map((item, index) => <li key={index}>{item.message}</li>)}
        </ul>)
      }
    }


    if (!this.props.show && !this.props.items) {
      return null;
    }

    return <div className={`alert alert-${this.props.type}`}>
      {this.props.message}
      {listItems}
    </div>;
  }
}
