import React from 'react'
import BooleanDropdown from '../boolean_dropdown'
import Input from '../forms/input'

export default class Bathroom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: props.details.attributes === undefined ? '' : props.details.attributes,
      roomIndex: props.roomIndex,
      roomId: props.details.id === undefined ? '' : props.details.id
    }
  }

  render() {
    const namePrefix = `property[rooms_attributes][${this.state.roomIndex}]`;

    return (
      <div>
        <input className="hidden" type="hidden" value={this.state.roomId} name={`${namePrefix}[id]`}/>
        <input className="hidden" type="hidden" value="Bathroom" name={`${namePrefix}[class]`}/>

        <Input label="Room size" className="room-size" name={`${namePrefix}[room_size]`} defaultValue={this.state.attributes.room_size} addOnAfter="m&sup2;" />

        <BooleanDropdown componentLabel="Is ensuite" componentName={`${namePrefix}[is_ensuite]`} defaultValue={this.state.attributes.is_ensuite} />

        <BooleanDropdown componentLabel="Storage" componentName={`${namePrefix}[storage]`} defaultValue={this.state.attributes.storage} />

        <div className="form-group shower">
          <label className="control-label">Shower</label>
          <select className="form-control" name={`${namePrefix}[shower]`} defaultValue={this.state.attributes.shower} >
            <option value="">Select an option:</option>
            <option value="none">None</option>
            <option value="overbath">Overbath</option>
            <option value="standalone">Standalone</option>
          </select>
        </div>

        <div className="form-group bath">
          <label className="control-label">Bathtub</label>
          <select className="form-control" name={`${namePrefix}[bath]`} defaultValue={this.state.attributes.bath} >
            <option value="">Select an option:</option>
            <option value="none">None</option>
            <option value="builtin">Built-in</option>
            <option value="freestanding">Freestanding</option>
          </select>
        </div>
      </div>
    )
  }
}
