import React from 'react';

const CsvParserPreview = ({ fields = [], data = [] }) => {
  return (
    <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
      <h2>Preview Results</h2>
      <table className="table table-striped table-condensed">
        <thead>
          <tr>
            {fields.map((field) => (
              <th key={field.key}>{field.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {fields.map((field) => (
                <td key={`${index}-${field.key}`}>{item[field.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CsvParserPreview;
