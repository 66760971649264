import React from 'react'

export default class Textarea extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`form-group ${this.props.className || ''}`}>
        {this.props.label != null &&
          <label className="control-label" htmlFor={this.props.id}>{this.props.label}</label>
        }
        {this._renderTextarea()}
      </div>
    )
  }

  _renderTextarea() {
    return (
        <textarea
          type="text"
          className="form-control"
          name={this.props.name}
          id={this.props.id}
          defaultValue={this.props.defaultValue}
          {...this.props.additionalParams}
        ></textarea>
    )
  }
}
