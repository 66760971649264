import React from 'react';
import Date from './forms/date';
import FileInput from './forms/file_input';

export default class PropertyCertificateList extends React.Component {
  render() {
    return (
      <div className={`property-certificate-list ${this.typeClass}`}>
        <h4 className="property-certificate-list__title">{this.props.title}</h4>
        <div className="form-group">
          {this.props.property_certificates.length > 0 ? this.renderTable() : 'No certificates to display'}
        </div>
      </div>
    );
  }

  renderTable() {
    return (
      <table>
        <tbody>{this.props.property_certificates.map(this.renderListItem)}</tbody>
      </table>
    );
  }

  renderListItem(property_certificate, index) {
    return (
      <tr key={index}>
        <td>
          <a className="btn btn-default btn-xs" href={property_certificate.url}>
            Download
          </a>
          &nbsp; Expires {property_certificate.expiry || 'Unknown'}
        </td>
      </tr>
    );
  }

  get typeClass() {
    return `property-certificate-list--${this.props.certificate_type}`;
  }
}
