import React from 'react';
import { joinClasses } from '~/utils/react_utils';

export default class Date extends React.Component {
  render() {
    const { helpText, errors, ...inputProps } = this.props;
    const classes = joinClasses(['form-group', 'date', this.props.errors && this.props.required && 'has-error']);
    return (
      <div className={classes}>
        <label className="control-label" htmlFor={this.props.name}>
          {this.props.required && <abbr title="required">* </abbr>}
          {this.props.label}
        </label>
        <div className="form-inline">
          <input className="form-control date" type="date" id={this.props.name} {...inputProps} />
        </div>
        {helpText && <p className="help-block">{helpText}</p>}
      </div>
    );
  }
}
