import React from 'react';
import Date from './forms/date';
import FileUploadInput from './forms/file_upload_input';

export default class PropertyCertificateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {inputModified: false};
  }

  render() {
    return (
      <div className="property-certificate-list__form">
        <Date
          className="required"
          name={`property[property_certificates][${this.props.certificate_type}][expiry]`}
          label="Expiry"
          onChange={this.inputChanged}
          required={this.state.inputModified}
        />
        <FileUploadInput
          id={`property_certificate_file_${this.props.certificate_type}`}
          className="required"
          name={`property[property_certificates][${this.props.certificate_type}][url]`}
          label={this.title}
          signedUrlEndpoint={this.props.signedUrlEndpoint}
          accept="image/jpg,image/png,application/pdf"
          onChange={this.inputChanged}
          required={this.state.inputModified}
        />
      </div>
    );
  }

  get title() {
    switch (this.props.certificate_type) {
      case 'epc':
        return 'EPC';
      case 'gas_certificate':
        return 'Gas safety certificate';
      case 'electrical_certificate':
        return 'Electricity certificate';
    }
  }

  inputChanged = () => {
    this.setState({inputModified: true});
  }
}
