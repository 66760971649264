import React from 'react';

export default class Integer extends React.Component {
  render() {
    return (
      <div className="form-group integer">
        <label className="control-label" htmlFor={this.props.name}>
          {this.props.label}
        </label>
        <div className="form-inline">
          <input className="form-control integer" type="number" step="1" id={this.props.name} {...this.props} />
        </div>
      </div>
    );
  }
}
