import React, { Component, Fragment } from 'react';

import Select from '~/components/forms/select';
import Input from '~/components/forms/input';
import Textarea from '~/components/forms/textarea';
import Delete from '~/components/forms/delete';
import Checkbox from '~/components/forms/checkbox';
import Date from '~/components/forms/date';
import { joinClasses } from '~/utils/react_utils';

export default class IncomeSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      income_source: props.income_source || {},
      cleared: false,
    };
  }

  get incomeSource() {
    return this.state.income_source;
  }

  updateIncomeSource = (changes) => this.setState({ income_source: { ...this.incomeSource, ...changes } });

  fieldName = (name) => `user[user_income_sources][${this.incomeSource.primary ? 'primary' : 'additional'}][${name}]`;

  render() {
    const innerClassName = joinClasses([this.state.cleared && 'for-delete']);
    return (
      <div className="income-source">
        <Input type="hidden" name={this.fieldName('id')} defaultValue={this.incomeSource.id} />
        <Delete
          name={this.fieldName('cleared')}
          onChange={(event) => this.setState({ cleared: event.target.checked })}
        />
        <div className={innerClassName}>
          <Select
            label="Type"
            name={this.fieldName('income_source_type')}
            options={this.typeOptions}
            includeBlank={true}
            defaultValue={this.incomeSource.income_source_type}
            onChange={(event) => this.updateIncomeSource({ income_source_type: event.target.value })}
          />
          {this.renderFields()}
        </div>
      </div>
    );
  }

  static primaryOnlyTypeOptions = [
    ['Employed', 'employed'],
    ['Self-employed', 'self-employed'],
  ];

  static commonTypeOptions = [
    ['Rental income', 'rental_income'],
    ['Dividends', 'dividends'],
    ['Freelance income', 'freelance'],
    ['Trust income', 'trust'],
    ['UK pension', 'uk_pension'],
    ['Other', 'other'],
    ['Student', 'student'],
    ['Unemployed', 'unemployed'],
  ];

  get typeOptions() {
    if (this.incomeSource.primary) {
      return [...this.constructor.primaryOnlyTypeOptions, ...this.constructor.commonTypeOptions];
    } else {
      return this.constructor.commonTypeOptions;
    }
  }

  renderFields = () => {
    switch (this.state.income_source.income_source_type) {
      case 'dividends':
        return this.dividendsFields();
      case 'employed':
        return this.employedFields();
      case 'freelance':
        return this.freelanceFields();
      case 'other':
        return this.otherFields();
      case 'rental_income':
        return this.rentalFields();
      case 'self-employed':
        return this.selfEmployedFields();
      case 'trust':
        return this.trustFields();
      case 'uk_pension':
        return this.ukPensionFields();
    }
  };

  dividendsFields = () => {
    return (
      <Fragment>
        <Input
          label="Gross annual income"
          type="number"
          additionalParams={{ step: 1, min: 0 }}
          name={this.fieldName('gross_annual_income')}
          defaultValue={this.incomeSource.gross_annual_income}
        />
        <Select
          label="Proof type"
          name={this.fieldName('proof_type')}
          options={[
            ['Accountant contact details', 'accountant_contact_details'],
            ['Shares confirmation and bank statement uploads', 'shares_confirmation'],
          ]}
          includeBlank={true}
          defaultValue={this.incomeSource.proof_type}
          onChange={(event) => this.updateIncomeSource({ proof_type: event.target.value })}
        />
        {this.incomeSource.proof_type === 'accountant_contact_details' && (
          <Fragment>
            <Input
              label="Accountant name"
              name={this.fieldName('referee_contact_name')}
              defaultValue={this.incomeSource.referee_contact_name}
            />
            <Input
              label="Accountant email"
              name={this.fieldName('referee_contact_email')}
              defaultValue={this.incomeSource.referee_contact_email}
            />
            <Input
              label="Accountant phone"
              name={this.fieldName('referee_contact_phone')}
              defaultValue={this.incomeSource.referee_contact_phone}
            />
            <Input type="hidden" name={this.fieldName('referee_type')} defaultValue="accountant" />
            {!this.incomeSource.reference_not_required && (
              <Input
                label="Accountant reference url"
                name={this.fieldName('reference_url')}
                defaultValue={this.incomeSource.reference_url}
              />
            )}
            <Checkbox
              label="Accountant reference not required"
              name={this.fieldName('reference_not_required')}
              defaultChecked={this.incomeSource.reference_not_required}
              onChange={(event) => this.updateIncomeSource({ reference_not_required: event.target.checked })}
              disabled={this.props.income_source.reference_not_required}
              /* Only disable this checkbox if the reference was already set to not required when we loaded the component */
            />
          </Fragment>
        )}
      </Fragment>
    );
  };

  employedFields = () => {
    return (
      <Fragment>
        <Input label="Employer" name={this.fieldName('employer')} defaultValue={this.incomeSource.employer} />
        <Select
          label="Sector"
          name={this.fieldName('employment_sector')}
          options={this.props.employment_sectors}
          includeBlank={true}
          defaultValue={this.incomeSource.employment_sector}
        />
        <Date
          label="Employment start date"
          name={this.fieldName('employment_start_date')}
          onChange={(event) => this.updateIncomeSource({ employment_start_date: event.target.value })}
          defaultValue={this.incomeSource.employment_start_date}
        />
        <Date
          label="Employment end date"
          name={this.fieldName('employment_end_date')}
          onChange={(event) => this.updateIncomeSource({ employment_start_date: event.target.value })}
          defaultValue={this.incomeSource.employment_end_date}
        />
        <Input label="Job title" name={this.fieldName('job_title')} defaultValue={this.incomeSource.job_title} />
        <Input
          label="Gross annual income"
          type="number"
          additionalParams={{ step: 1, min: 0 }}
          name={this.fieldName('gross_annual_income')}
          defaultValue={this.incomeSource.gross_annual_income}
        />
        <Textarea
          label="Description"
          name={this.fieldName('description')}
          defaultValue={this.incomeSource.description}
          additionalParams={{ rows: 4 }}
        />
        <Input
          label="Employer referee name"
          name={this.fieldName('referee_contact_name')}
          defaultValue={this.incomeSource.referee_contact_name}
        />

        <Select
          label="Employer referee position"
          name={this.fieldName('referee_position')}
          options={[
            ['HR', 'hr'],
            ['Manager', 'manager'],
            ['Director', 'director'],
          ]}
          includeBlank={true}
          defaultValue={this.incomeSource.referee_position}
          onChange={(event) => this.updateIncomeSource({ referee_position: event.target.value })}
        />

        <Input
          label="Employer referee email"
          name={this.fieldName('referee_contact_email')}
          defaultValue={this.incomeSource.referee_contact_email}
        />
        <Input
          label="Employer referee phone"
          name={this.fieldName('referee_contact_phone')}
          defaultValue={this.incomeSource.referee_contact_phone}
        />
        <Input type="hidden" name={this.fieldName('referee_type')} defaultValue="employer" />
        <Checkbox
          label="Employment reference not required"
          name={this.fieldName('reference_not_required')}
          defaultChecked={this.incomeSource.reference_not_required}
          onChange={(event) => this.updateIncomeSource({ reference_not_required: event.target.checked })}
          disabled={this.props.income_source.reference_not_required}
          /* Only disable this checkbox if the reference was already set to not required when we loaded the component */
        />
      </Fragment>
    );
  };

  freelanceFields = () => {
    return (
      <Fragment>
        <Input
          label="Gross annual income"
          type="number"
          additionalParams={{ step: 1, min: 0 }}
          name={this.fieldName('gross_annual_income')}
          defaultValue={this.incomeSource.gross_annual_income}
        />
        <Select
          label="Proof type"
          name={this.fieldName('proof_type')}
          options={[
            ['Accountant contact details', 'accountant_contact_details'],
            ['Client reference', 'client_reference'],
            ['Tax return uploads', 'tax_returns'],
          ]}
          includeBlank={true}
          defaultValue={this.incomeSource.proof_type}
          onChange={(event) => this.updateIncomeSource({ proof_type: event.target.value })}
        />
        {['accountant_contact_details', 'client_reference'].includes(this.incomeSource.proof_type) && (
          <Fragment>
            <Input
              name={this.fieldName('referee_type')}
              type="hidden"
              defaultValue={
                {
                  accountant_contact_details: 'accountant',
                  client_reference: 'client',
                }[this.incomeSource.proof_type]
              }
            />
            <Input
              label="Referee name"
              name={this.fieldName('referee_contact_name')}
              defaultValue={this.incomeSource.referee_contact_name}
            />
            <Input
              label="Referee email"
              name={this.fieldName('referee_contact_email')}
              defaultValue={this.incomeSource.referee_contact_email}
            />
            <Input
              label="Referee phone"
              name={this.fieldName('referee_contact_phone')}
              defaultValue={this.incomeSource.referee_contact_phone}
            />
            {!this.incomeSource.reference_not_required && (
              <Input
                label="Reference url"
                name={this.fieldName('reference_url')}
                defaultValue={this.incomeSource.reference_url}
              />
            )}
            <Checkbox
              label="Reference not required"
              name={this.fieldName('reference_not_required')}
              defaultChecked={this.incomeSource.reference_not_required}
              onChange={(event) => this.updateIncomeSource({ reference_not_required: event.target.checked })}
              disabled={this.props.income_source.reference_not_required}
              /* Only disable this checkbox if the reference was already set to not required when we loaded the component */
            />
          </Fragment>
        )}
      </Fragment>
    );
  };

  otherFields = () => {
    return (
      <Fragment>
        <Input
          label="Gross annual income"
          type="number"
          additionalParams={{ step: 1, min: 0 }}
          name={this.fieldName('gross_annual_income')}
          defaultValue={this.incomeSource.gross_annual_income}
        />
        <Textarea
          label="Description"
          name={this.fieldName('description')}
          defaultValue={this.incomeSource.description}
          additionalParams={{ rows: 4 }}
        />
      </Fragment>
    );
  };

  rentalFields = () => {
    return (
      <Fragment>
        <Input
          label="Gross annual income"
          type="number"
          additionalParams={{ step: 1, min: 0 }}
          name={this.fieldName('gross_annual_income')}
          defaultValue={this.incomeSource.gross_annual_income}
        />
      </Fragment>
    );
  };

  selfEmployedFields = () => {
    return this.freelanceFields();
  };

  trustFields = () => {
    return (
      <Fragment>
        <Input
          label="Gross annual income"
          type="number"
          additionalParams={{ step: 1, min: 0 }}
          name={this.fieldName('gross_annual_income')}
          defaultValue={this.incomeSource.gross_annual_income}
        />
        <Select
          label="Proof type"
          name={this.fieldName('proof_type')}
          options={[
            ['Accountant contact details', 'accountant_contact_details'],
            ['Trustee contact details', 'trustee_contact_details'],
            ['Trust income statement uploads', 'trust_income_statement'],
          ]}
          includeBlank={true}
          defaultValue={this.incomeSource.proof_type}
          onChange={(event) => this.updateIncomeSource({ proof_type: event.target.value })}
        />
        {['accountant_contact_details', 'trustee_contact_details'].includes(this.incomeSource.proof_type) && (
          <Fragment>
            <Input
              name={this.fieldName('referee_type')}
              type="hidden"
              defaultValue={
                {
                  accountant_contact_details: 'accountant',
                  trustee_contact_details: 'trustee',
                }[this.incomeSource.proof_type]
              }
            />
            <Input
              label="Referee name"
              name={this.fieldName('referee_contact_name')}
              defaultValue={this.incomeSource.referee_contact_name}
            />
            <Input
              label="Referee email"
              name={this.fieldName('referee_contact_email')}
              defaultValue={this.incomeSource.referee_contact_email}
            />
            <Input
              label="Referee phone"
              name={this.fieldName('referee_contact_phone')}
              defaultValue={this.incomeSource.referee_contact_phone}
            />
            {!this.incomeSource.reference_not_required && (
              <Input
                label="Reference url"
                name={this.fieldName('reference_url')}
                defaultValue={this.incomeSource.reference_url}
              />
            )}
            <Checkbox
              label="Reference not required"
              name={this.fieldName('reference_not_required')}
              defaultChecked={this.incomeSource.reference_not_required}
              onChange={(event) => this.updateIncomeSource({ reference_not_required: event.target.checked })}
              disabled={this.props.income_source.reference_not_required}
              /* Only disable this checkbox if the reference was already set to not required when we loaded the component */
            />
          </Fragment>
        )}
      </Fragment>
    );
  };

  ukPensionFields = () => {
    return (
      <Fragment>
        <Input
          label="Gross annual income"
          type="number"
          additionalParams={{ step: 1, min: 0 }}
          name={this.fieldName('gross_annual_income')}
          defaultValue={this.incomeSource.gross_annual_income}
        />
      </Fragment>
    );
  };
}
