import React from 'react'
import Input from '../forms/input'

export default class ReceptionRoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: props.details.attributes === undefined ? '' : props.details.attributes,
      roomIndex: props.roomIndex,
      roomId: props.details.id === undefined ? '' : props.details.id
    }
  }

  render() {
    const namePrefix = `property[rooms_attributes][${this.state.roomIndex}]`;

    return (
      <div>
        <input className="hidden" type="hidden" value={this.state.roomId} name={`${namePrefix}[id]`}/>
        <input className="hidden" type="hidden" value="ReceptionRoom" name={`${namePrefix}[class]`}/>

        <Input label="Room size" className="room-size" name={`${namePrefix}[room_size]`} defaultValue={this.state.attributes.room_size} addOnAfter="m&sup2;" />

        <div className="form-group room-type">
          <label className="control-label">Room type</label>
          <select className="form-control" name={`${namePrefix}[room_type]`} defaultValue={this.state.attributes.room_type} >
            <option value="">Select an option:</option>
            <option value="living">Living</option>
            <option value="dining">Dining</option>
            <option value="livingdining">Living / Dining</option>
          </select>
        </div>
      </div>
    )
  }
}
