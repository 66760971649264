import React from 'react';
import Room from './room';
import InputSection from './layouts/input_section_header';

export default class RoomList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: props.rooms === undefined ? [] : props.rooms,
    };
  }

  render() {
    const children = this.state.rooms.map((room, i) => <Room key={i} roomIndex={i} details={room} />);

    return (
      <InputSection title={I18n.t('properties.form.room_list_heading')}>
        <div className="property-rooms react-list">{children}</div>
        <small className="pull-left">{this._renderAddButton()}</small>
      </InputSection>
    );
  }

  _renderAddButton() {
    return (
      <button onClick={this.addRoom} className="btn btn-primary">
        {I18n.t('properties.form.rooms.add')}
      </button>
    );
  }

  addRoom = (event) => {
    event.preventDefault();
    this.setState({ rooms: [...this.state.rooms, {}] });
  };
}
