import React from 'react';
import useDataImportWatcher from './use_data_import_watcher';

const ImportStatusIndicator = ({ id, status, review_count }) => {
  const liveValues = useDataImportWatcher({ id, status, review_count });

  return (
    <span className={`data_import__status--${liveValues.status}`}>
      {I18n.t(`data_import.statuses.${liveValues.status}`)}
    </span>
  );
};

export default ImportStatusIndicator;
