import React, { Component, Fragment } from 'react';
import { addDays, parseISO, format } from 'date-fns';

import TermItem from '~/components/term_item';
import Submit from '~/components/forms/submit';
import InputSection from '~/components/layouts/input_section_header';

import HttpRequestor from '~/utils/http_requestor';

export default class TermsCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: this.props.terms,
      showTermAdded: false,
      addButtonDisabled: !this.props.staff_type_admin,
    };
    this.http = new HttpRequestor();
  }

  addNewTerm = (event) => {
    event.preventDefault();

    let currentTerms = this.state.terms;
    currentTerms.unshift(this.emptyTerm);

    this.setState({
      terms: currentTerms,
      addButtonDisabled: true,
    });
  };

  addSavedTerm = (term) => {
    let terms = this.state.terms;
    terms.splice(0, 1, term);
    this.setState({ terms, addButtonDisabled: false, showTermAdded: true });
  };

  onTermUpdated = (term) => {
    let terms = this.state.terms;
    const termToUpdate = terms.findIndex((t) => t.id == term.id);

    terms.splice(termToUpdate, 1, term);
    this.setState({ terms });
  };

  removeTerm = (removedTerm) => {
    const terms = this.state.terms.filter((term) => term.id !== removedTerm.id);
    this.setState({ terms });
  };

  removeUnsavedTerm = () => {
    let terms = this.state.terms;
    terms.splice(0, 1);
    this.setState({ terms, addButtonDisabled: false });
  };

  render() {
    return (
      <Fragment>
        <div>
          {this._renderTermsHeader()}
          <small className="pull-right term__add">{this._renderAddButton()}</small>
        </div>
        {this._renderTerms()}
      </Fragment>
    );
  }

  _renderTermsHeader = () => {
    return <InputSection title="Terms" />;
  };

  _renderAddButton = () => {
    return <Submit label="Add Term" disabled={this.state.addButtonDisabled} onClick={this.addNewTerm} />;
  };

  _renderTerms = () => {
    return this.state.terms.map((term, index) => {
      return (
        <TermItem
          id={term.id}
          key={term.id}
          index={index}
          term={term}
          timezone={this.props.timezone}
          offer_id={this.props.offer_id}
          onTermAdd={this.addSavedTerm}
          onTermDeleted={this.removeTerm}
          removeUnsavedTerm={this.removeUnsavedTerm}
          currency={this.props.currency}
          numberOfTerms={this.state.terms.length}
          onTermUpdated={this.onTermUpdated}
          useOrganisationPaymentDay={this.props.use_organisation_payment_day}
          useProRataUpfrontPayment={this.props.use_pro_rata_upfront_payment}
        />
      );
    });
  };

  get emptyTerm() {
    return {
      id: 0,
      contracts: [],
      offer_id: this.props.offer_id,
      original_price_in_pence: this.props.original_price_in_pence,
      price_in_pence: null,
      start_date: this.start_date,
      end_date: null,
      original_end_date: null,
      residents_break_date: null,
      residents_break_notice_in_months: null,
      our_break_date: null,
      our_break_notice_in_months: null,
      upfront_rent_months: null,
      upfront_rent_amount: null,
      total_upfront_furniture_in_pence: null,
      required_fields: [],
    };
  }

  get start_date() {
    const { terms } = this.state;
    if (terms.length == 0 || terms[0].end_date == null) {
      return null;
    }
    let start_date = addDays(parseISO(terms[0].end_date), 1);
    return format(start_date, 'yyyy-MM-dd');
  }
}
