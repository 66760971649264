import axios from 'axios';
import React, { useEffect, useState } from 'react';

import Select from 'components/forms/select';
import CsvParser from 'components/csv_parser';

import resourceFields from './resource_fields';

const DataImporter = ({
  authToken,
  currentUser,
  resources,
  redirectTo,
  managementOrgOptions,
  countryOptions,
  portfolioEnabled,
  managementAgreementOptions,
  portfolioOptions,
}) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [resource, setResource] = useState(resources[0][1]);
  const [managementId, setManagementId] = useState('');
  const [managementOrgName, setManagementOrgName] = useState();
  const [managementIdRequired, setManagementIdRequired] = useState(false);
  const [country, setCountry] = useState('');
  const [importType, setImportType] = useState();
  const [managementAgreement, setManagementAgreement] = useState('');
  const [portfolioId, setPortfolio] = useState('');

  const allowImport = () => {
    let allowResourceImport = true;
    if (resource === 'properties') {
      allowResourceImport = importType === 'create_only' ? !!country : true;
    }
    return resource && importType && allowResourceImport;
  };

  useEffect(() => {
    setCountry('');
    setData([]);
    setManagementId('');
    setManagementAgreement('');
    setPortfolio('');
  }, [resource]);

  useEffect(() => {
    setManagementIdRequired(
      data.some(
        (item) => item['management_organisation_property_identifier'] || item['management_organisation_stay_identifier']
      )
    );
  }, [data]);

  const onImport = (items) => {
    const request = {
      data: {
        resource,
        items,
        additional_params: {
          country,
          managementId,
          managementOrgName,
          managementAgreement,
          portfolioId,
          imported_by: { id: currentUser.id, name: currentUser.name }, // TODO: Can we do this on the backend
          import_type: importType,
        },
      },
      headers: {
        'X-CSRF-Token': authToken,
      },
      method: 'POST',
      url: '/data_imports',
    };

    axios(request)
      .then(() => {
        window.location = redirectTo;
      })
      .catch((err) => {
        setError(err);
      });
  };

  const calculateResource = (e) => {
    const res = resources.find((r) => r[1] === e.target.value)[1];
    return res;
  };

  const onResourceSelect = (e) => setResource(calculateResource(e));

  const onActionSelect = (e) => setImportType(e.target.value);

  const importActionOptions = () => [
    [I18n.t('data_import.actions.create_only', { resource }), 'create_only'],
    [I18n.t('data_import.actions.create_and_update', { resource }), 'create_and_update'],
  ];

  const handleManagementOrgChange = (e) => {
    setManagementId(e.target.value);
    setManagementOrgName(managementOrgOptions[e.target.selectedIndex - 1][0]);
  };

  const renderPortfolioMissingError = resource == 'properties' && portfolioEnabled && !portfolioId;
  const renderMgmtOrgMissingError = managementIdRequired && !managementId && !portfolioEnabled;
  const renderSubmitButton = !renderPortfolioMissingError && !renderMgmtOrgMissingError;

  return (
    <div>
      <Select
        onChange={onResourceSelect}
        options={resources}
        includeBlank={false}
        label={I18n.t('data_import.resource_select')}
        value={resource}
      />

      <AdditionalFields
        resource={resource}
        managementOrgOptions={managementOrgOptions}
        countryOptions={countryOptions}
        managementAgreementOptions={managementAgreementOptions}
        country={country}
        managementId={managementId}
        managementAgreement={managementAgreement}
        setCountry={setCountry}
        setManagementId={setManagementId}
        setManagementAgreement={setManagementAgreement}
        handleManagementOrgChange={handleManagementOrgChange}
        portfolioId={portfolioId}
        portfolioOptions={portfolioOptions}
        setPortfolio={setPortfolio}
        portfolioEnabled={portfolioEnabled}
      />

      <Select
        onChange={onActionSelect}
        options={importActionOptions()}
        includeBlank={true}
        label={I18n.t('data_import.action_select')}
        value={importType}
      />

      {error && <ErrorDisplay error={error} />}

      {allowImport() && <CsvParser fields={resourceFields[resource]} onConfirm={(results) => setData(results)} />}
      {data.length > 0 && (
        <>
          {renderPortfolioMissingError && (
            <div className="alert alert-danger">
              A Portfolio has not been selected. All properties must have a Portfolio assigned.
            </div>
          )}

          {renderMgmtOrgMissingError && (
            <div className="alert alert-danger">
              Management Org is required when data includes a Mngmt Org Property/Stay ID
            </div>
          )}

          {renderSubmitButton && (
            <p>
              <button className="btn btn-primary" onClick={() => onImport(data)}>
                Submit
              </button>
            </p>
          )}
        </>
      )}
    </div>
  );
};

const AdditionalFields = ({
  resource,
  managementOrgOptions,
  countryOptions,
  managementAgreementOptions,
  country,
  managementId,
  managementAgreement,
  handleManagementOrgChange,
  setCountry,
  setManagementAgreement,
  portfolioId,
  portfolioOptions,
  setPortfolio,
  portfolioEnabled,
}) => {
  const countrySelect = (
    <Select
      key="properties_country"
      onChange={(e) => setCountry(e.target.value)}
      options={countryOptions}
      includeBlank={true}
      label={I18n.t('data_import.country_select')}
      value={country}
    />
  );

  const managementIdSelect = (
    <Select
      key="properties_management_org"
      onChange={(e) => handleManagementOrgChange(e)}
      options={managementOrgOptions}
      includeBlank={true}
      label={I18n.t('data_import.management_select')}
      value={managementId}
    />
  );

  const managementAgreementSelect = (
    <Select
      key="properties_management_agreement"
      onChange={(e) => setManagementAgreement(e.target.value)}
      options={managementAgreementOptions}
      includeBlank={true}
      label={I18n.t('data_import.management_agreement_select')}
      value={managementAgreement}
    />
  );

  const portfolioSelect = (
    <Select
      key="portfolio"
      onChange={(e) => setPortfolio(e.target.value)}
      options={portfolioOptions}
      includeBlank={true}
      label={I18n.t('data_import.portfolio_select')}
      value={portfolioId}
    />
  );

  switch (resource) {
    case 'stays':
      return [managementIdSelect];
    case 'properties':
      return portfolioEnabled
        ? [countrySelect, managementAgreementSelect, portfolioSelect]
        : [countrySelect, managementIdSelect, managementAgreementSelect];
    case 'residents':
      return [managementIdSelect];
    default:
      return null;
  }
};

const ErrorDisplay = ({ error }) => {
  return (
    <div className="alert alert-danger" role="alert">
      <p>{I18n.t('data_import.error_title')}</p>
      {error.response ? <p>{error.response.data.message}</p> : <p>{error.message}</p>}
    </div>
  );
};

export default DataImporter;
