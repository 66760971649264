import React, { Component, Fragment } from 'react';

import Select from './forms/select';
import Input from './forms/input';
import Textarea from './forms/textarea';

export default class EmploymentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employment_status: this.props.employment_status,
    };
  }

  render() {
    return (
      <Fragment>
        <Select
          label="Employment status"
          name="user[employment_status]"
          options={this.props.employment_statuses}
          defaultValue={this.state.employment_status}
          onChange={(event) => this.setState({ employment_status: event.target.value })}
        />
        {this._renderStudentFields()}
      </Fragment>
    );
  }

  _renderStudentFields = () => {
    if (this.state.employment_status == 'student') {
      return this.studentFields();
    }
  };

  studentFields = () => {
    return (
      <Fragment>
        <Input
          label="Study Institution"
          name="user[study_institution]"
          key="user[study_institution]"
          id="study_institution"
          defaultValue={this.props.study_institution}
        />
        <Input
          label="Study course"
          name="user[study_course]"
          key="user[study_course]"
          id="study_course"
          defaultValue={this.props.study_course}
        />
      </Fragment>
    );
  };
}
