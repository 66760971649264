import React from 'react';

export default function InputSection({ children, title }) {
  return (
    <div className="input-section-header clearfix">
      <div className="input-section-header__header">
        <h3 className="input-section-header__title">{title}</h3>
        <hr className="input-section-header__divider" />
      </div>
      {children}
    </div>
  );
}
