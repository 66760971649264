import React, { Component } from 'react';

export default class OrganisationSearchItem extends Component {
  componentDidUpdate() {
    if (this.props.active === 'active') {
      this.props.scrollIntoView(this.searchResult);
    }
  }

  render() {
    const { item, active } = this.props;

    return (
      <div ref={(result) => (this.searchResult = result)} className="search-bar__result">
        <a href={item.url} className={`list-group-item ${active}`}>
          <p className="search-bar__result-id">Organisation #{item.id}</p>

          <h4 className="search-bar__result-heading list-group-item-heading">
            <p className="search-bar__result-title">{item.name}</p>
          </h4>
        </a>
      </div>
    );
  }
}
