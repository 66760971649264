import React from 'react';
import moment from 'moment-timezone';

export default class DateTime extends React.Component {
  render() {
    return (
      <div className="form-group datetime">
        <label className="control-label" htmlFor={this.props.name}>
          {this.props.label}
        </label>
        <div className="form-inline">
          <input
            className="form-control datetime"
            type="datetime-local"
            id={this.props.name}
            {...this.props}
            defaultValue={this._defaultValue}
          />
        </div>
      </div>
    );
  }

  get _defaultValue() {
    if (this.props.defaultValue == null) {
      return null;
    }

    if (this.props.timezone) {
      const dateStr = moment(this.props.defaultValue).tz(this.props.timezone).format('YYYY-MM-DDTHH:mm');
      return dateStr;
    }
    
    let dateStr = new Date(this.props.defaultValue).toISOString();
    let dateVal = dateStr.slice(0, dateStr.length - 2);
    return dateVal;
  }
}
