import React from 'react';
import ResidentIcon from 'images/residents.svg';

export default function ResidentFacing({ children }) {
  return (
    <div className="well well-warning property-form-group">
      <div className="property-form-group__inner">
        <div className="property-form-group__header">
          <img src={ResidentIcon} className="property-form-group__icon" />
          <h3 className="property-form-group__title">Resident Facing</h3>
        </div>
        {children}
      </div>
    </div>
  );
}
