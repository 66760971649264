import React, { Component } from 'react';
import Input from '~/components/forms/input';
import Checkbox from '~/components/forms/checkbox';
import Submit from '~/components/forms/submit';
import Alert from '~/components/forms/alert';
import DeleteIcon from '~/components/forms/delete_icon';

import HttpRequestor from '~/utils/http_requestor';

export default class ClientSignatoryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      clientSignatory: this.props.clientSignatory,
      isLoading: false,
      success: false,
    };
    this.http = new HttpRequestor();
  }

  onChange = (event) => {
    let { name, value } = event.target;

    const checkboxes = ['is_company', 'power_of_attorney'];
    if (checkboxes.includes(name)) {
      value = event.target.checked;
    }

    let clientSignatory = { ...this.state.clientSignatory, [name]: value };
    this.setState({ clientSignatory });
  };

  onSave = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      error: false,
    });

    setTimeout(() => {
      this.saveSignatory();
    }, 1000);
  };

  saveSignatory = () => {
    this.http
      .call(this.saveUrl, this.saveMethod, this.state.clientSignatory)
      .then((response) => {
        if (this.unsaved) {
          this.props.onSignatoryAdded(response);
        }
        this.handleSuccess();
      })
      .catch((e) => {
        this.handleError();
      });
  };

  onDeleteClick = (event) => {
    event.preventDefault();
    const prompt = confirm('Do you want to delete this Signatory?');

    if (prompt) {
      this.deleteSignatory();
    }
  };

  deleteSignatory = () => {
    if (this.unsaved) {
      return this.props.removeUnsavedSignatory();
    }
    this.http
      .call(this.clientSignatoryUrl, 'DELETE')
      .then((response) => {
        this.props.onSignatoryDeleted(response);
      })
      .catch((e) => {
        this.handleError();
      });
  };

  handleSuccess = () => {
    this.setState({ success: true, isLoading: false }, () => {
      window.setTimeout(() => {
        this.setState({ success: false });
      }, 4000);
    });
  };

  handleError = () => {
    this.setState({ error: true, isLoading: false });
  };

  get hasError() {
    return this.state.error;
  }

  get saveMethod() {
    return this.props.id ? 'PATCH' : 'POST';
  }

  get saveUrl() {
    return this.props.id ? this.clientSignatoryUrl : this.postClientSignatoryUrl;
  }

  get unsaved() {
    return !this.props.id;
  }

  get buttonLabel() {
    if (this.state.isLoading) {
      return 'Saving...';
    }
    return this.unsaved ? 'Add New Signatory' : 'Update Signatory';
  }

  get postClientSignatoryUrl() {
    return `/leases/${this.props.leaseId}/client_signatories/`;
  }

  get clientSignatoryUrl() {
    return `/leases/${this.props.leaseId}/client_signatories/${this.props.id}`;
  }

  get showDeleteIcon() {
    return this.unsaved || this.props.numberOfSignatories > 1;
  }

  renderDeleteIcon() {
    if (!this.showDeleteIcon) {
      return;
    }

    return (
      <div className="client-signatory__delete pull-right">
        <DeleteIcon name={`client-signatory_${this.props.id}`} onChange={this.onDeleteClick} />
      </div>
    );
  }

  render() {
    const clientSignatory = this.props.clientSignatory;

    return (
      <form onSubmit={this.onSave}>
        <div className="col-sm-6">
          <div className="well">
            <h3>
              Client Signatory #{this.props.index + 1}
              {this.renderDeleteIcon()}
            </h3>
            <div>
              <Input
                id={`managed_for_${this.props.index}`}
                label='Managed on behalf of ("Client"):'
                name="managed_for"
                required={true}
                defaultValue={clientSignatory.managed_for}
                hint="Who we are managing this property for."
                handleChange={this.onChange}
              />
            </div>
            <div>
              <Input
                id={`client_signatory_${this.props.index}`}
                label="Client Signatory:"
                name="client_signatory"
                required={true}
                defaultValue={clientSignatory.client_signatory}
                hint="Who should be shown as signatory on the contract?"
                handleChange={this.onChange}
              />
            </div>
            <div>
              <Input
                id={`company_registration_number_${this.props.index}`}
                label="Company registration number:"
                name="company_registration_number"
                defaultValue={clientSignatory.company_registration_number}
                hint="This will be shown on the contract if filled out."
                handleChange={this.onChange}
              />
            </div>
            <div>
              <Input
                id={`client_legal_address_${this.props.index}`}
                label="Client legal address:"
                name="client_legal_address"
                required={true}
                defaultValue={clientSignatory.client_legal_address}
                hint="This is the address that will be shown on the contract."
                handleChange={this.onChange}
              />
            </div>
            <div>
              <Input
                id={`client_email_${this.props.index}`}
                label="Client email:"
                name="client_email"
                type="email"
                required={true}
                defaultValue={clientSignatory.client_email}
                hint="Email address contracts will be issued too, unless power of attorney is selected below."
                handleChange={this.onChange}
              />
            </div>
            <div>
              <Checkbox
                label="Owner is a Company"
                name="is_company"
                defaultChecked={clientSignatory.is_company}
                hint="Is Client an individual or a company?"
                handleChange={this.onChange}
              />
            </div>
            <div>
              <Checkbox
                label="Power of Attorney"
                name="power_of_attorney"
                defaultChecked={clientSignatory.power_of_attorney}
                hint="If we have power of attorney for the signatory, issued contracts will be sent to us"
                handleChange={this.onChange}
              />
            </div>

            <div className="row">
              <div className="form-group clearfix">
                <div className="col-sm-3">
                  <Submit
                    label={this.buttonLabel}
                    disabled={this.state.isLoading}
                    className={'client-signatory__add'}
                  />
                </div>
                <div className="col-sm-9">
                  <Alert
                    show={this.state.success}
                    type="success"
                    message="Your changes to this signatory have been saved :)"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <Alert
                  show={this.hasError}
                  type="danger"
                  message="There was an error applying your changes, please try again or contact @engineers"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
