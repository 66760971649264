import React from 'react'
import BooleanDropdown from './boolean_dropdown'
import Input from './forms/input'
import Delete from './forms/delete'
import Textarea from './forms/textarea'

export default class Utility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: props.details.attributes === undefined ? '' : props.details.attributes,
      utilityId: props.details.id === undefined ? '' : props.details.id,
      namePrefix: `property[utilities_attributes][${props.utilityIndex}]`,
      deleteReady: false
    }
  }

  render() {
    const namePrefix = `property[utilities_attributes][${this.props.utilityIndex}]`;

    return (
      <div className={`utility ${this.deleteItemClass()}`}>
        {this._renderSelectType()}

        <input className="hidden" type="hidden" value={this.state.utilityId} name={`${namePrefix}[id]`}/>

        <Input label="Price per month" defaultValue={(this.state.attributes.price_in_pence / 100).toFixed(2)} name={`${namePrefix}[price_in_pounds]`} addOnBefore="£" additionalParams={{type: 'number', step: '.01'}}/>
      </div>
    )
  }

  _renderSelectType() {
    return (
      <div className="form-group type-select">
        <select className="form-control type-select-dropdown" name={`${this.state.namePrefix}[title]`} onChange={this.setType} defaultValue={this.state.attributes.title} required>
          <option value="">Select an option:</option>
          <option value="Electricity">Electricity</option>
          <option value="Gas">Gas</option>
          <option value="Electricity & Gas">Electricity & Gas</option>
          <option value="Water">Water</option>
          <option value="TV licence">TV licence</option>
          <option value="Internet">Internet</option>
        </select>

        <Delete name={`property[utilities_attributes][${this.props.utilityIndex}][delete]`} onChange={this.setDeleteReady}/>
      </div>
    )
  }

  deleteItemClass() {
    return this.state.deleteReady ? 'for-delete' : ''
  }

  setDeleteReady = (event) => {
    this.setState({deleteReady: !this.state.deleteReady})
  }

  setMeter = (event) => {
    this.setState({
      attributes: {
        ...this.state.attributes,
        meter: event.target.value
      }
    });
  }

  setType = (event) => {
    this.setState({
      attributes: {
        ...this.state.attributes,
        title: event.target.value
      }
    })
  }
}
