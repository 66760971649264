import React, { Component } from 'react';
import Selectivity from 'selectivity/react';
import PropTypes from 'prop-types';
import { joinClasses } from '../utils/react_utils';

export default class OfferAssignee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffID: this.props.defaultValue,
    };
  }

  render() {
    const classes = joinClasses([
      'offer-assignee',
      !this.state.staffID && 'offer-assignee--unassigned',
      this.state.updating && 'offer-assignee--disabled',
    ]);

    return (
      <div className={classes}>
        <Selectivity.React
          className="offer-assignee__select"
          classNamePrefix="offer-assignee-select"
          allowClear={true}
          placeholder="Unassigned"
          value={this.state.staffID}
          onChange={this.onChange}
          items={this.props.items}
        />
        {this.state.error && (
          <p className="offer-assignee__error">Could not assign stay, please contact Engineering.</p>
        )}
      </div>
    );
  }

  onChange = (selectedOption) => {
    const staffID = selectedOption.value;
    this.setState({ updating: true, error: false }, () => this.onStaffIDChange(staffID));
  };

  onStaffIDChange = async (staffID) => {
    try {
      const req = await fetch(this.props.assignOfferUrl, {
        method: 'PATCH',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': this.props.authenticityToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        credentials: 'same-origin',
        body: JSON.stringify({ assigned_to_staff_id: staffID }),
      });

      if (!req.ok) {
        return this.setError();
      }

      this.setState({ staffID, updating: false });
    } catch (e) {
      return this.setError();
    }
  };

  setError = () => {
    this.setState({ updating: false, error: true });
  };
}

OfferAssignee.propTypes = {
  defaultValue: PropTypes.number,
  assignOfferUrl: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};
