import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileInput from './file_input';
import { forOwn } from 'lodash';
import 'whatwg-fetch';

export default class FileUploadInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      text: null,
    };
  }

  render() {
    return (
      <div className="file-upload-input">
        <input className="file-upload-input__value" name={this.props.name} type="hidden" value={this.previewUrl} />
        <FileInput
          {...this.passThroughProps}
          name=""
          className="file-upload-input__input"
          handleChange={this.onFileChange}
        />
        {this.state.text && <p className="file-upload-input__text">{this.state.text}</p>}
        {this.showLink && (
          <a className="file-upload-input__link" href={this.previewUrl} target="_blank">
            Download file
          </a>
        )}
      </div>
    );
  }

  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({ file });
  };

  get passThroughProps() {
    const { signedUrlEndpoint, beforeUpload, afterUpload, name, ...otherProps } = this.props;
    return otherProps;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.file && this.state.file !== prevState.file) {
      this.uploadFile(this.state.file);
    }
  }

  fetchSignedUrl = (file) => () => {
    return fetch(this.signedUrlEndpoint(file), {
      method: 'GET',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }).then((response) => response.json());
  };

  postToSignedUrl = (file) => (createResponse) => {
    return fetch(createResponse.postUrl, {
      method: 'POST',
      body: this.postToSignedUrlArgs(file, createResponse),
    })
      .then((response) => response.text())
      .then((body) => {
        const url = this.extractUploadedUrl(body);
        this.setState({ previewUrl: url });
        return url;
      });
  };

  postToSignedUrlArgs = (file, response) => {
    let data = new FormData();
    forOwn(response.postFields, function(value, key) {
      data.append(key, value);
    });
    data.append('file', file);
    return data;
  };

  extractUploadedUrl = (body) => {
    return body.match(/<Location>(.*?)<\/Location>/)[1];
  };

  uploadFile = (file) => {
    this.startUpload()
      .then(this.beforeUpload)
      .then(this.fetchSignedUrl(file))
      .then(this.postToSignedUrl(file))
      .then(this.afterUpload)
      .then(this.finishUpload)
      .catch(this.handleError);
  };

  startUpload = () => {
    this.toggleSubmitButton(false);
    this.setState({
      text: 'Uploading...',
      previewUrl: null,
    });
    return Promise.resolve();
  };

  beforeUpload = () => {
    if (this.props.beforeUpload) {
      return this.props.beforeUpload();
    }
    return Promise.resolve();
  };

  afterUpload = (url) => {
    if (this.props.afterUpload) {
      return this.props.afterUpload(url);
    }
    return Promise.resolve();
  };

  finishUpload = () => {
    this.toggleSubmitButton(true);
    this.setState({ text: null });
  };

  withCacheBuster(url) {
    return url + '?' + new Date().getTime();
  }

  fileExtension(file) {
    return file.name.split('.').pop();
  }

  signedUrlEndpoint = (file) => {
    return this.props.signedUrlEndpoint + '?file_extension=' + this.fileExtension(file);
  };

  handleError = (e) => {
    this.setState({ text: 'Upload failed 😰' });
    console.error(e);
    this.toggleSubmitButton(true);
    return e;
  };

  toggleSubmitButton = (toggle) => {
    if (this.props.uploading) {
      return;
    }
    const button = document.getElementById('submit-button');
    if (button) {
      button.disabled = !toggle;
    }
  };

  get previewUrl() {
    if (this.state.previewUrl) {
      return this.withCacheBuster(this.state.previewUrl);
    }
    if (this.props.initialProcessedUrl) {
      return this.withCacheBuster(this.props.initialProcessedUrl);
    }
    return '';
  }

  get previewUrl() {
    if (this.state.previewUrl) {
      return this.state.previewUrl;
    }
    return '';
  }

  get showLink() {
    return !!this.previewUrl;
  }
}

FileUploadInput.propTypes = {
  signedUrlEndpoint: PropTypes.string,
  beforeUpload: PropTypes.func,
  afterUpload: PropTypes.func,
};
