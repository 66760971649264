module.exports = [
  {
    label: 'Our User ID',
    key: 'id',
    alternates: ['ID', 'Residently ID'],
  },
  {
    label: 'Mngmt Org Stay ID',
    key: 'management_organisation_stay_identifier',
    alternates: ['Reference'],
  },
  {
    label: 'Mngmt Org Stay User ID',
    key: 'management_organisation_stay_user_identifier',
    alternates: ['Resident Record Number'],
  },
  {
    label: 'First Name',
    key: 'first_name',
  },
  {
    label: 'Last Name',
    key: 'last_name',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Mobile',
    key: 'mobile',
  },
];
