import React from 'react'
import BooleanDropdown from '../boolean_dropdown'
import Input from '../forms/input'

export default class Bedroom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: props.details.attributes === undefined ? '' : props.details.attributes,
      roomIndex: props.roomIndex,
      roomId: props.details.id === undefined ? '' : props.details.id
    }
  }

  render() {
    const namePrefix = `property[rooms_attributes][${this.state.roomIndex}]`;

    return (
      <div>
        <input className="hidden" type="hidden" value={this.state.roomId} name={`${namePrefix}[id]`}/>
        <input className="hidden" type="hidden" value="Bedroom" name={`${namePrefix}[class]`}/>

        <Input label="Name" className="room-name" name={`${namePrefix}[name]`} defaultValue={this.state.attributes.name} />
        <Input label="Room size" className="room-size" name={`${namePrefix}[room_size]`} defaultValue={this.state.attributes.room_size} addOnAfter="m&sup2;" />

        <div className="form-group room-master">
          <BooleanDropdown componentLabel="Master?" componentName={`${namePrefix}[master]`} defaultValue={this.state.attributes.master} />
        </div>

        <div className="form-group max-bed-size">
          <label className="control-label">Maximum Bed Size</label>
          <select className="form-control" name={`${namePrefix}[max_bed_size]`} defaultValue={this.state.attributes.max_bed_size}>
            <option value="">Select an option:</option>
            <option value="single">Single</option>
            <option value="double">Double</option>
            <option value="queen">Queen</option>
            <option value="king">King</option>
            <option value="superking">Superking</option>
          </select>
        </div>

        <BooleanDropdown componentLabel="Has ensuite?" componentName={`${namePrefix}[has_ensuite]`} defaultValue={this.state.attributes.has_ensuite} />

        <BooleanDropdown componentLabel="Wardrobe" componentName={`${namePrefix}[wardrobe]`} defaultValue={this.state.attributes.wardrobe} />
      </div>
    )
  }
}
