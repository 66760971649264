import propertyFields from './property_fields';
import stayFields from './stay_fields';
import residentFields from './resident_fields';

const resourceFields = {
  properties: propertyFields,
  stays: stayFields,
  residents: residentFields,
};

export default resourceFields;
