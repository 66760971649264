import ReactDOM from 'react-dom';

const bindReactTurbolinksDespud = () => {
  document.addEventListener('turbolinks:before-cache', () => {
    const reactContainers = document.querySelectorAll('[data-react-class]');
    reactContainers.forEach((node) => {
      ReactDOM.unmountComponentAtNode(node);
    });
  });
};

export default bindReactTurbolinksDespud;
